<template>
    <div class="row q-col-gutter-sm">

        <q-dialog v-model="dialogAntecedentesHeredofamiliares" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Antecedentes Heredofamiliares</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select v-model="objeto.diagnostico" emit-value map-options @filter="filtroDiagnosticos"
                                new-value-mode="add" :options="itemsfiltroDiagnosticos" use-input input-debounce="0"
                                dense filled label="Nombre de la enfermedad" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-8">
                            <q-select v-model="objeto.familiar" new-value-mode="add" emit-value map-options
                                @filter="filtroFamiliares" :options="itemsfiltroFamiliares" use-input input-debounce="0"
                                dense filled label="Familiar con la enfermedad" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-4">
                            <q-input dense type="number" min="0" max="150" placeholder="Valor entre 0 a 150" required
                                v-model="objeto.edad" filled label="Edad al diagnóstico">
                                <template v-slot:after>
                                    <q-btn round color="primary" icon="mdi-plus" @click="agregar()">
                                        <q-tooltip>
                                            Agregar
                                        </q-tooltip>
                                    </q-btn>
                                </template>
                                <q-tooltip>
                                    Valor entero de 0 a 150
                                </q-tooltip>
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregar()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>ANTECEDENTES HEREDOFAMILIARES</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-plus" @click="dialogAntecedentesHeredofamiliares = true">
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->

        <div class="col-12 row">
            <div class="doc-h2">Antecedentes Heredofamiliares</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Antecedente"
                @click="dialogAntecedentesHeredofamiliares = true" />
            <!-- <q-btn  class="text-blue" round color="white"
                    icon="mdi-plus" >
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn> -->
            <div class="element-linea"></div>
        </div>

        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md" row-key="enfermedad"
                :data="paciente.antecHeredofammiliares" :columns="columns" :rows-per-page-options="[0]" separator="cell"
                no-data-label="Sin datos disponibles">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense @click="eliminar(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="diagnostico" :props="props">{{ props.row.diagnostico }}
                        </q-td>
                        <q-td key="familiar" :props="props">
                            {{ props.row.familiar }}
                        </q-td>
                        <q-td key="edad" :props="props">
                            {{ props.row.edad }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsfiltroDiagnosticos: null,
            itemsfiltroFamiliares: null,
            objeto: {
                familiar: '',
                diagnostico: '',
                edad: 0
            },
            familiar: '',
            diagnostico: '',
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'diagnostico', align: 'center', label: 'Enfermedad', field: 'diagnostico', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'familiar', align: 'center', label: 'Familiar', field: 'familiar', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'edad', align: 'center', label: 'Edad', field: 'edad', sortable: true, classes: 'text-right', },
            ],
            data: [],
            dialogAntecedentesHeredofamiliares: false
        }
    },
    computed: {
        paciente() {
            return this.$store.state.pacienteStore;
        },
        itemsDiagnosticosCIE() {
            return this.$store.state.listaCatalogoDiagnosticosCIE
        },
        itemsFamiliares() {
            return this.$store.state.listaCatalogoFamiliares
        }
    },
    watch: {
    },
    created() {
    },
    methods: {
        filtroDiagnosticos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroDiagnosticos = this.itemsDiagnosticosCIE.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroDiagnosticos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroFamiliares(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroFamiliares = this.itemsFamiliares.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroFamiliares.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        agregar() {
            if (this.objeto.diagnostico == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una enfermedad' })
                return;
            }

            if (this.objeto.familiar == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un familiar' })
                return;
            }
            // if (this.objeto.edad == 0) {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una edad mayor a 0' })
            //     return;
            // }
            if (this.objeto.edad < 0 || this.objeto.edad > 150) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'La edad debe estar entre 0 y 150' })
                this.objeto.edad = 0
                return;
            }
            this.$store.state.pacienteStore.antecHeredofammiliares.push(this.objeto);

            this.objeto = {
                diagnostico: '',
                familiar: '',
                edad: 0
            }
        },

        eliminar(data) {
            let editedIndex = this.$store.state.pacienteStore.antecHeredofammiliares.indexOf(data)
            this.$store.state.pacienteStore.antecHeredofammiliares.splice(editedIndex, 1)
        },

    }
}
</script>