<template>
  <q-page class="q-pa-lg">
    <!-- DIALOGO PARA CREAR UN NUEVO CONSULTORIO -->
    <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
      <consultorio @cierraVentana="dialogNuevo = false"></consultorio>
    </q-dialog>

    <div class="row flex-left">
      <!-- LOGO DE ONE ANALITICA -->
      <div class="col-12 col-md-6 col-sm-6 text-left ">
        <span v-if="$store.state.isDark == true" class="q-pa-lg shadow-6 mb-3 inline-block surface-card logo-inicio"
          style="border-radius: 10px; width: 230px; height: 50px;">
        </span>
        <span v-else class="q-pa-lg shadow-0 mb-3 inline-block surface-card logo-inicio-negro"
          style=" width: 230px; height: 50px;">
        </span>
      </div>
      <!-- BOTONES DE ACCION -->
      <div class="col-12 col-md-6 col-sm-6 text-right">
        <q-select v-model="selectMedico" emit-value map-options @filter="filtroMedicos" :options="itemsFiltroMedicos"
          use-input input-debounce="0" option-label="usuario_principal" dense filled label="Médico"
          virtual-scroll-slice-size="1" new-value-mode="add" />
        <q-btn size="md" class="btn-estilos q-mr-sm" icon="mdi-plus" unelevated rounded
          style="background:#1A4161; color:white" label="Nuevo Consultorio" @click="nuevoConsultorio()" />
        <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-update"
          @click="GetConsultorios()" />
      </div>
    </div>
    <div>
      <q-table title="Consultorios" :data="items" :columns="columns" row-key="nombre" :filter="filter"
        :loading="loading" :pagination="pagination">
        <template v-slot:loading>
          <q-inner-loading showing color="primary" />
        </template>
        <template v-slot:top-right>
          <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width key="acciones">
              <template v-if="$store.state.isDark">
                <q-btn size="md" style="color:#686868" rounded flat dense @click="editarConsultorio(props.row)"
                  icon="mdi-pencil">
                  <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                    content-style="font-size: 14px">Editar</q-tooltip>
                </q-btn>
              </template>
              <template v-if="!$store.state.isDark">
                <q-btn size="md" style="color:#1A4161" rounded flat dense @click="editarConsultorio(props.row)"
                  icon="mdi-pencil">
                  <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                    content-style="font-size: 14px">Editar</q-tooltip>
                </q-btn>
              </template>
            </q-td>
            <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
            <q-td key="numero_consultorio" :props="props">{{ props.row.numero_consultorio }}</q-td>
            <q-td key="liga_maps" :props="props">{{ props.row.liga_maps }}</q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-page>
</template>
<script>
import moment from "moment";
import { QSpinnerCube } from "quasar";
import axios from "axios";
import consultorio from "./Consultorio.vue";

export default {
  components: {
    consultorio,
  },
  data() {
    return {
      filter: "",
      loading: false,
      columns: [
        { name: "acciones", align: "center", label: "Acciones", field: "acciones", },
        { name: "nombre", align: "center", label: "Nombre", field: "nombre", sortable: true, },
        { name: "numero_consultorio", align: "center", label: "Num. Consultorio", field: "numero_consultorio", sortable: true, },
        { name: "liga_maps", align: "center", label: "Maps", field: "liga_maps", sortable: true, },
      ],
      data: [],
      dialogNuevo: false,
      pagination: {
        sortBy: "nombre",
        descending: false,
        page: 1,
        rowsPerPage: 10,
      },
      dialogConfirm: false,
      selectMedico: null,
      itemsFiltroMedicos: null,
      itemsMedicos: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },

    items() {
      return this.$store.state.listaConsultoriosStore;
    },

    // itemsMedicos() {
    //   return this.$store.state.listaEmpleadosStore;
    // },
  },
  created() {
    this.Iniciales();

  },
  methods: {
    async Iniciales() {
      this.$store.state.listaConsultoriosStore = [];
      await this.GetEmpleados();
      await this.GetUsuariosRegistrados();
    },

    nuevoConsultorio() {
      if (!this.selectMedico) {
        this.$q.notify({
          type: 'warning',
          message: 'Seleccione un médico de la lista superior',
          actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
        })
        return;
      }
      let consultorio = {
        _id: "",
        nombre: "",
        id_medico: this.selectMedico.id_usuario_principal,
        usuario_creador: this.token.username,
        numero_interior: "",
        numero_exterior: "",
        calle: "",
        colonia: "",
        municipio: null,
        estado: "",
        pais: "",
        referencia: "",
        liga_maps: "",
        numero_consultorio: "",
        fechaCreacion: new Date(),
      };
      this.$store.state.consultorioStore = { ...consultorio };
      this.dialogNuevo = true;
    },

    async GetConsultorios() {
      if (!this.selectMedico) {
        this.$q.notify({
          type: 'warning',
          message: 'Seleccione un médico de la lista superior',
          actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
        })
        return;
      }
      try {
        this.loading = true;
        this.$store.state.listaConsultoriosStore = [];
        let response = await axios.get("Catalogos/GetConsultoriosAsync/dev/" + this.selectMedico.id_usuario_principal);
        let consultorios = response.data;
        this.$store.state.listaConsultoriosStore = consultorios;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    editarConsultorio(data) {
      if (!this.selectMedico) {
        this.$q.notify({
          type: 'warning',
          message: 'Seleccione un médico de la lista superior',
          actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
        })
        return;
      }
      this.$store.state.consultorioStore = { ...data };
      this.dialogNuevo = true;
    },

    async GetEmpleados() {
      try {
        let response = await axios.get("Login/GetEmpleados/dev");
        let catalogo = response.data;
        this.$store.state.listaEmpleadosStore = catalogo;
      } catch (error) {
        console.log(error);
      }
    },

    filtroMedicos(val, update, abort) {
      update(
        () => {
          const needle = val.toLocaleLowerCase();
          this.itemsFiltroMedicos = this.itemsMedicos.filter(
            (v) => v.usuario_principal.toLocaleLowerCase().indexOf(needle) > -1
          );
        },
        (ref) => {
          if (val !== "" && this.itemsFiltroMedicos.length > 0) {
            ref.setOptionIndex(-1);
            ref.moveOptionSelection(1, true);
          }
        }
      );
    },

    async GetUsuariosRegistrados() {
      this.itemsMedicos = [];
      let userName = this.token.username;
      let listaUsusarios = this.$store.state.listaEmpleadosStore;
      let usuario = listaUsusarios.find(
        (f) => f.username === userName
      );
      let id_usuario = usuario._id;

      this.itemsUsuarios = [];
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Consultando. Espere...', messageColor: 'white' })
      try {
        let userA = this.usuarioActual;
        let response = await axios.get("Agenda/GetUsuariosRelacionadosAsync/dev/" + id_usuario);
        this.itemsMedicos = response.data;
        this.$q.loading.hide()
      } catch (error) {
        this.$q.loading.hide()
      }
    },

  },
};
</script>

<style></style>
<style lang="sass"></style>
