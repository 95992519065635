<template>
  <q-page class="q-pa-lg q-column q-gutter-md">
    <!-- Selector de Pacientes -->

    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Archivo de Audio</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <q-file v-model="selectedFile" dense autofocus label="Seleccionar archivo de audio" @input="handleFileChange">
            <template v-slot:before>
              <q-icon name="attachment" />
            </template>
          </q-file>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Cancelar" v-close-popup />
          <q-btn flat label="Enviar" @click="" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-select v-model="$store.state.pacienteConstanzaStore" emit-value map-options @filter="filtroPacientes"
      :options="itemsfiltroPacientes" use-input input-debounce="0" option-label="paciente" dense filled label="Paciente"
      virtual-scroll-slice-size="1" new-value-mode="add" @input="LimpiarDatos()" />

    <!-- Área de Mensajes -->
    <q-scroll-area class="q-flex-grow-1 q-pa-sm">
      <template v-for="a in mensajes">
        <q-chat-message v-if="a.tipo == 'receptor'" :name="token.username" :text="[a.mensaje]" :stamp="a.fecha"
          :sent="true" bg-color="amber-7" />
        <q-chat-message v-else name="Constanza" avatar="https://cdn.quasar.dev/img/avatar5.jpg" :text="[a.mensaje]"
          :stamp="a.fecha" text-color="white" bg-color="primary" />
      </template>

      <template v-if="loadingMensaje">
        <q-chat-message avatar="https://cdn.quasar.dev/img/avatar5.jpg" name="Constanza" bg-color="primary">
          <q-spinner-dots class="q-ml-sm" size="2rem" />
        </q-chat-message>
      </template>
    </q-scroll-area>

    <!-- Campo de Entrada de Mensajes -->
    <q-input filled bottom-slots @keydown.enter="enviarMensajeConEnter($event)" autogrow v-model="text" label="Mensaje"
      dense>
      <template v-slot:before>
        <q-avatar>
          <img src="https://cdn.quasar.dev/img/boy-avatar.png" />
        </q-avatar>
        <q-btn class="q-ml-md" round dense flat icon="attachment" @click="prompt = true" />
      </template>

      <template v-slot:append>
        <q-icon v-if="text !== ''" name="close" @click="text = ''" class="cursor-pointer" />
      </template>

      <template v-slot:after>
        <q-btn round dense flat icon="send" @click="sendMensaje3()" />
      </template>
    </q-input>
  </q-page>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { format } from "date-fns";
import { parse } from "date-fns";
import { endOfMonth } from "date-fns";
import { es } from "date-fns/locale";
import { QSpinnerCube } from "quasar";
export default {
  components: {},
  data() {
    return {
      idConversacion: "",
      text: "",
      itemsfiltroPacientes: null,
      mensajes: [
      ],
      mensaje: {
        numMensaje: 0,
        tipo: "",
        mensaje: "",
        fecha: "",
      },
      loadingMensaje: false,
      prompt: false,
      selectedFile: null,
      filePath: "",
      base64Audio: ''
    };
  },
  computed: {
    itemsPacientes() {
      return this.$store.state.listaPacienteStore;
    },
    token() {
      return this.$store.state.usuario;
    },
  },
  watch: {},
  created() { },
  methods: {
    LimpiarDatos() {
      this.mensajes = [];
      this.idConversacion = "";
    },
    filtroPacientes(val, update, abort) {
      update(
        () => {
          const needle = val.toLocaleLowerCase();
          this.itemsfiltroPacientes = this.itemsPacientes.filter(
            (v) => v.paciente.toLocaleLowerCase().indexOf(needle) > -1
          );
        },
        (ref) => {
          if (val !== "" && this.itemsfiltroPacientes.length > 0) {
            ref.setOptionIndex(-1);
            ref.moveOptionSelection(1, true);
          }
        }
      );
    },

    handleFileChange() {
      console.log(this.selectedFile)
      const file = this.selectedFile;
      this.convertToBase64(file);
    },

    convertToBase64(file) {
      console.log(file)

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result.split(',')[1];
        this.base64Audio = base64String;
        console.log(this.base64Audio);
      };
      reader.onerror = () => {
        console.error('Error al leer el archivo.');
      };
      reader.readAsDataURL(file);
    },

    enviarMensajeConEnter(event) {
      if (!event.shiftKey) {
        event.preventDefault();
        this.sendMensaje3();
      }
    },

    obtenerFechaHoraActual() {
      const ahora = new Date();
      const fecha = `${ahora.getDate()}/${ahora.getMonth() + 1
        }/${ahora.getFullYear()}`;
      const horas = ahora.getHours().toString().padStart(2, "0");
      const minutos = ahora.getMinutes().toString().padStart(2, "0");
      const segundos = ahora.getSeconds().toString().padStart(2, "0");
      return `${fecha} ${horas}:${minutos}:${segundos}`;
    },

    async sendMensajeNull() {
      if (
        this.$store.state.pacienteConstanzaStore.paciente == "" ||
        this.$store.state.pacienteConstanzaStore.paciente == null
      ) {
        this.$q.notify({
          type: "warning",
          position: "top-right",
          message: "Indique el nombre del paciente.",
        });
        return;
      }

      if (this.text == "") {
        return;
      }

      let data = {
        prompt: this.text,
        paciente_id: this.$store.state.pacienteConstanzaStore._id,
      };

      const nuevoNumMensaje =
        this.mensajes.length > 0
          ? this.mensajes[this.mensajes.length - 1].numMensaje + 1
          : 1;
      let nuevoMensaje = {
        numMensaje: nuevoNumMensaje,
        tipo: "receptor",
        mensaje: this.text,
        fecha: this.obtenerFechaHoraActual(),
      };
      this.text = "";

      this.mensajes.push(nuevoMensaje);

      this.loadingMensaje = true;
      try {
        const response = await axios.post(
          "https://api-ia.contago.com.mx/",
          data
        );
        console.log(response);

        this.loadingMensaje = false;

        const nuevoNumMensaje =
          this.mensajes.length > 0
            ? this.mensajes[this.mensajes.length - 1].numMensaje + 1
            : 1;
        let nuevoMensaje = {
          numMensaje: nuevoNumMensaje,
          tipo: "emisor",
          mensaje: response.data.response.replace("Constanza:", "").trim(),
          fecha: this.obtenerFechaHoraActual(),
        };
        this.mensajes.push(nuevoMensaje);
      } catch (error) {
        this.loadingMensaje = false;
        console.log("ia", error);
      }
    },

    async sendMensaje3() {
      if (
        this.$store.state.pacienteConstanzaStore.paciente == "" ||
        this.$store.state.pacienteConstanzaStore.paciente == null
      ) {
        this.$q.notify({
          type: "warning",
          position: "top-right",
          message: "Indique el nombre del paciente.",
        });
        return;
      }

      if (this.text == "") {
        return;
      }

      let formData = new FormData();
      formData.append("input_text", this.text);
      // formData.append('paciente_id', '6606e4346d3201428a4756a3');
      formData.append(
        "paciente_id",
        this.$store.state.pacienteConstanzaStore._id
      );
      formData.append("conversation_id", this.idConversacion);
      formData.append("empleado_id", this.token._id);

      const nuevoNumMensaje =
        this.mensajes.length > 0
          ? this.mensajes[this.mensajes.length - 1].numMensaje + 1
          : 1;
      let nuevoMensaje = {
        numMensaje: nuevoNumMensaje,
        tipo: "receptor",
        mensaje: this.text,
        fecha: this.obtenerFechaHoraActual(),
      };
      this.text = "";

      this.mensajes.push(nuevoMensaje);

      this.loadingMensaje = true;

      try {
        const response = await axios.post(
          "https://onetrain.duckdns.org:5002/chat",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response);

        this.loadingMensaje = false;

        if (response.status == 200) {
          if (this.idConversacion == "") {
            this.idConversacion = response.data.conversation_id;
          }
          const nuevoNumMensaje =
            this.mensajes.length > 0
              ? this.mensajes[this.mensajes.length - 1].numMensaje + 1
              : 1;
          let nuevoMensaje = {
            numMensaje: nuevoNumMensaje,
            tipo: "emisor",
            mensaje: response.data.response.replace("Constanza:", "").trim(),
            fecha: this.obtenerFechaHoraActual(),
          };

          this.mensajes.push(nuevoMensaje);
        }
      } catch (error) {
        this.loadingMensaje = false;
        console.log("ia", error);
      }
    },


  },
};
</script>
<style scoped>
.q-page {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.q-flex-grow-1 {
  flex-grow: 1;
}
</style>
