<template>
    <div class="row q-col-gutter-sm">

        <!-- ACTIVIDADES FISICAS -->
        <q-dialog v-model="dialogActividadesFisicas" persistent>
            <q-card style="min-width: 1100px;">
                <q-bar class="bg-primary text-white">
                    <div>Actividades Fisicas</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-10">
                            <q-select dense filled v-model="actividad.actividad" use-input input-debounce="0"
                                new-value-mode="add" :options="itemsfiltroActividadesFisicas"
                                @filter="filtroActividades" label="Actividades Fisicas" />
                        </div>
                        <div class="col-12 col-md-2">
                            <q-input dense type="number" min="0" max="168" placeholder="Valor entre 0 y 168" filled
                                label="Horas al Día" v-model="actividad.horas">
                                <!-- <template v-slot:after>
                    <q-btn round color="primary" icon="mdi-plus" @click="agregarActividad()">
                        <q-tooltip>
                            Agregar
                        </q-tooltip>
                    </q-btn>
                </template> -->
                                <q-tooltip>
                                    Valor entre 0 y 168
                                </q-tooltip>
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarActividad()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- ALCOHOLISMO -->
        <q-dialog v-model="dialogAlcoholismo" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Alcoholismo</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select v-model="bebida.bebida" new-value-mode="add" emit-value map-options
                                @filter="filtroBebidas" :options="itemsfiltroBebidas" use-input input-debounce="0" dense
                                filled label="Tipo de bebida alcoholica" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-select v-model="bebida.frecuencia" dense class="full-width" filled
                                :options="['DIARIO', 'SEMANAL', 'QUINCENAL', 'CADA MES']" label="Frecuencia" />
                        </div>

                        <div class="col-12 col-md-6">
                            <q-input v-model="bebida.numCopas" min="1" max="100" dense type="number" filled
                                label="Número de copas">

                                <q-tooltip>
                                    Valor entre 1 y 100
                                </q-tooltip>
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarBebida()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- OTRAS TOXICOMANIAS -->
        <q-dialog v-model="dialogOtrasToxicomanias" persistent>
            <q-card style="min-width: 1100px;">
                <q-bar class="bg-primary text-white">
                    <div>Otras Toxicomanías</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-10">
                            <q-select v-model="toxi.toxicomania" new-value-mode="add" emit-value map-options
                                @filter="filtroToxicomanias" :options="itemsfiltroToxicomanias" use-input
                                input-debounce="0" dense filled label="Toxicomanía" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-2">
                            <q-select v-model="toxi.frecuencia" dense class="full-width" filled
                                :options="['DIARIO', 'SEMANAL', 'QUINCENAL', 'CADA MES']" label="Frecuencia">
                            </q-select>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarToxicomania()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>ANTECEDENTES PERSONALES NO PATOLÓGICOS</div>
                <q-space />
            </q-bar>
        </div> -->

        <div class="col-12 row">
            <div class="doc-h2">Antecedentes Personales No Patológicas</div>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-3">
            <q-select v-model="paciente.escolaridad" emit-value map-options @filter="filtroEscolaridad"
                :options="itemsfiltroEscolaridad" use-input input-debounce="0" dense filled label="Escolaridad"
                virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-3">
            <q-select v-model="paciente.religion" emit-value map-options @filter="filtroReligiones"
                :options="itemsfiltroReligiones" use-input input-debounce="0" dense filled label="Religión"
                virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-3">
            <q-select v-model="paciente.ocupacion" emit-value map-options @filter="filtroOcupaciones"
                :options="itemsfiltroOcupaciones" use-input input-debounce="0" dense filled label="Ocupación"
                virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-3">
            <q-select v-model="paciente.estadoCivil" emit-value map-options @filter="filtrEstadosCiviles"
                :options="itemsfiltroEstadosCiviles" use-input input-debounce="0" dense filled label="Estado Civil"
                virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-4">
            <q-input v-model="paciente.comidasDia" min="1" max="10" dense type="number" filled label="Comidas al Día"
                @change="validaComidasDia()">
                <q-tooltip>
                    Valor entre 1 y 10
                </q-tooltip>
            </q-input>
        </div>
        <div class="col-12 col-md-4">
            <q-input v-model="paciente.calidadAlimentacion" min="0" max="5" dense type="number" filled
                label="Calidad de alimentación" @change="validaCalidad">
                <q-tooltip>
                    Escala de alimentación del 0 al 5 donde 0 es muy mala y 5 es muy buena
                </q-tooltip>
            </q-input>
        </div>
        <div class="col-12 col-md-4">
            <q-select v-model="paciente.hemotipo" emit-value map-options @filter="filtroHemotipo"
                :options="itemsfiltroHemotipo" use-input input-debounce="0" dense filled label="Hemotipo"
                virtual-scroll-slice-size="1" />
        </div>
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>LUGAR DE PROCEDENCIA</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Lugar de Procedencia</div>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-4">
            <q-select v-model="paciente.procedencia.pais" emit-value map-options @filter="filtroPaises"
                :options="itemsfiltroPaises" use-input input-debounce="0" dense filled label="País de procedencia"
                virtual-scroll-slice-size="1" />
        </div>
        <div v-show="paciente.procedencia.pais == 'MEXICO'" class="col-12 col-md-4">
            <q-select v-model="paciente.procedencia.estado" emit-value map-options @filter="filtroEstados"
                :options="itemsfiltroEstados" use-input input-debounce="0" dense filled label="Estado de procedencia"
                virtual-scroll-slice-size="1" />
        </div>
        <div v-show="paciente.procedencia.pais == 'MEXICO'" class="col-12 col-md-4">
            <q-select v-model="paciente.procedencia.municipio" emit-value map-options @filter="filtroMunicipios"
                option-label="municipio" :options="itemsfiltroMunicipios" use-input input-debounce="0" dense filled
                label="Municipio de procedencia" virtual-scroll-slice-size="1" />
        </div>
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>LUGAR DE RESIDENCIA</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Lugar de Residencia</div>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-4">
            <q-select v-model="paciente.residencia.pais" emit-value map-options @filter="filtroPaises"
                :options="itemsfiltroPaises" use-input input-debounce="0" dense filled label="País de residencia"
                virtual-scroll-slice-size="1" />
        </div>
        <div v-show="paciente.residencia.pais == 'MEXICO'" class="col-12 col-md-4">
            <q-select v-model="paciente.residencia.estado" emit-value map-options @filter="filtroEstados"
                :options="itemsfiltroEstados" use-input input-debounce="0" dense filled label="Estado de residencia"
                virtual-scroll-slice-size="1" />
        </div>
        <div v-show="paciente.residencia.pais == 'MEXICO'" class="col-12 col-md-4">
            <q-select v-model="paciente.residencia.municipio" emit-value map-options @filter="filtroMunicipios"
                option-label="municipio" :options="itemsfiltroMunicipios" use-input input-debounce="0" dense filled
                label="Municipio de residencia" virtual-scroll-slice-size="1" />
        </div>

        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>CONVIVENCIA CON ANIMALES</div>
                <q-space />
            </q-bar>
        </div> -->

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Convivencia con Animales</div>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select dense filled v-model="paciente.convAnimales" use-input use-chips multiple input-debounce="0"
                @new-value="createValueAnimales" :options="itemsfiltroAnimales" @filter="filtroAnimales"
                label="Convivencia con animales" />
        </div>
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>ZOONOSIS</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Zoonosis</div>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select dense filled v-model="paciente.zoonosis" use-input use-chips multiple input-debounce="0"
                @new-value="createValueZoonosis" :options="itemsfiltroZoonosis" @filter="filtroZonosis"
                label="Zoonosis" />
        </div>
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>INTOLERANCIA ALIMIMENTARIA</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Intolerancia Alimentaria</div>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-12">
            <q-select filled v-model="paciente.intolerancias" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueAlimentos" :options="itemsfiltroAlimentos" @filter="filtroAlimentos"
                label="Intolerancia a Alimentos" />
        </div>
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>ALERGIAS</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Alergias</div>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="paciente.alergias" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueAlergias" :options="itemsfiltroAlergias" @filter="filtroAlergias"
                label="Alergias" />
        </div>


        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>ACTIVIDADES FISICAS</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-plus" @click="dialogActividadesFisicas = true">
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Actividades Fisicas</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Actividad"
                @click="dialogActividadesFisicas = true" />
            <div class="element-linea"></div>
        </div>

        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="paciente.actividadesFisicas" :columns="columnsActividadesFisicas" :rows-per-page-options="[0]"
                separator="cell" no-data-label="Sin datos disponibles" row-key="actividad">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarActividad(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="actividad" :props="props">{{ props.row.actividad }}
                        </q-td>
                        <q-td key="horas" :props="props">
                            {{ props.row.horas }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>EXPOSICIÓN A BIOMASA</div>
                <q-space />
            </q-bar>
        </div> -->

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Exposicion a Biomasa</div>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-2">
            <q-input dense type="number" min="0" max="24" v-model="paciente.exposicionBiomasa.horas" filled
                label="Horas al Día" @change="validaHorasBiomasa">
                <q-tooltip>
                    Valor entre 0 y 24
                </q-tooltip>
            </q-input>
        </div>

        <div class="col-12 col-md-2">
            <q-input dense type="number" min="0" max="100" v-model="paciente.exposicionBiomasa.años" filled
                label="Años con exposición" @change="validaAñosBiomasa">
                <q-tooltip>
                    Valor entre 0 y 100
                </q-tooltip>
            </q-input>
        </div>
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>TABAQUISMO</div>
                <q-space />
            </q-bar>
        </div> -->

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Tabaquismo</div>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-2">
            <q-input dense type="number" min="0" max="100" filled label="Cigarrillos al día"
                v-model="paciente.tabaquismo.numCigarros" @change="validaCigarrosDia">
                <q-tooltip>
                    Valor entre 0 y 100
                </q-tooltip>
            </q-input>
        </div>

        <div class="col-12 col-md-2">
            <q-input dense type="number" min="0" max="150" filled label="Años fumando"
                v-model="paciente.tabaquismo.años">
                <q-tooltip>
                    Valor entre 0 y la edad del paciente
                </q-tooltip>
            </q-input>
        </div>

        <!-- <div class="col-12 col-md-2">
            <q-input dense type="number" filled label="Índice Tabáquico" v-model="paciente.tabaquismo.indice" />
        </div> -->
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>ALCOHOLISMO</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-plus" @click="dialogAlcoholismo = true">
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Alcoholismo</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Bebida" @click="dialogAlcoholismo = true" />
            <div class="element-linea"></div>
        </div>
        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md" :data="paciente.alcoholismo"
                :columns="columnsBebidas" :rows-per-page-options="[0]" separator="cell"
                no-data-label="Sin datos disponibles" row-key="bebida">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense @click="eliminarBebida(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="bebida" :props="props">{{ props.row.bebida }}
                        </q-td>
                        <q-td key="frecuencia" :props="props">
                            {{ props.row.frecuencia }}
                        </q-td>
                        <q-td key="numCopas" :props="props">
                            {{ props.row.numCopas }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>OTRAS TOXICOMANÍAS</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-plus" @click="dialogOtrasToxicomanias = true">
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Otras Toxicomanías</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Toxicomanía"
                @click="dialogOtrasToxicomanias = true" />
            <div class="element-linea"></div>
        </div>
        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md" :data="paciente.toxicomanias"
                :columns="columnsToxicomanias" :rows-per-page-options="[0]" row-key="toxicomania" separator="cell"
                no-data-label="Sin datos disponibles">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarToxicomania(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="toxicomania" :props="props">{{ props.row.toxicomania }}
                        </q-td>
                        <q-td key="frecuencia" :props="props">
                            {{ props.row.frecuencia }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsfiltroPaises: null,
            itemsfiltroEstados: null,
            itemsfiltroMunicipios: null,
            itemsfiltroReligiones: null,
            itemsfiltroEscolaridad: null,
            itemsfiltroEstadosCiviles: null,
            itemsfiltroOcupaciones: null,
            itemsfiltroAnimales: null,
            itemsfiltroZoonosis: null,
            itemsfiltroActividadesFisicas: null,
            itemsfiltroAlimentos: null,
            itemsfiltroAlergias: null,
            itemsfiltroHemotipo: null,
            itemsfiltroBebidas: null,
            itemsfiltroToxicomanias: null,
            actividad: {
                actividad: '',
                horas: ''
            },
            columnsActividadesFisicas: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'actividad', align: 'center', label: 'Actividad', field: 'actividad', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'horas', align: 'center', label: 'Horas al día', field: 'horas', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
            ],
            bebida: {
                bebida: '',
                frecuencia: '',
                numCopas: '',
            },

            columnsBebidas: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'bebida', align: 'center', label: 'Bebida Alcoholica', field: 'bebida', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'frecuencia', align: 'center', label: 'Fecuencia', field: 'frecuencia', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'numCopas', align: 'center', label: 'Número de copas', field: 'numCopas', sortable: true, classes: 'text-right', },
            ],
            toxi: {
                toxicomania: '',
                frecuencia: '',
            },
            columnsToxicomanias: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'toxicomania', align: 'center', label: 'Toxicomanía', field: 'toxicomania', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'frecuencia', align: 'center', label: 'Fecuencia', field: 'frecuencia', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
            ],

            dialogActividadesFisicas: false,
            dialogAlcoholismo: false,
            dialogOtrasToxicomanias: false,
        }
    },
    computed: {
        paciente() {
            return this.$store.state.pacienteStore.antecPersonalesNoPatologicos;
        },
        itemsPaises() {
            return this.$store.state.listaCatalogoPaises
        },
        itemsEstados() {
            return this.$store.state.listaCatalogoEstados
        },
        itemsMunicipios() {
            return this.$store.state.listaCatalogoMunicipios
        },
        itemsReligiones() {
            return this.$store.state.listaCatalogoReligiones
        },
        itemsOcupaciones() {
            return this.$store.state.listaCatalogoOcupaciones
        },
        itemsEstadosCiviles() {
            return this.$store.state.listaCatalogoEstadosCiviles
        },
        itmesEscolaridad() {
            return this.$store.state.listaCatalogoEscolaridad
        },
        itmesAnimales() {
            return this.$store.state.listaCatalogoAnimales
        },
        itmesZoonosis() {
            return this.$store.state.listaCatalogoZoonosis
        },
        itmesActividadesFisicas() {
            return this.$store.state.listaCatalogoActividadesFisicas
        },
        itmesIntolerancias() {
            return this.$store.state.listaCatalogoIntolerancias
        },
        itmesAlergias() {
            return this.$store.state.listaCatalogoAlergias
        },
        itmesHemotipos() {
            return this.$store.state.listaCatalogoHemotipos
        },
        itmesBebidas() {
            return this.$store.state.listaCatalogoBebidas
        },
        itmesToxicomanias() {
            return this.$store.state.listaCatalogoToxicomanias
        },
    },
    watch: {
    },
    created() {
    },
    methods: {
        filtroPaises(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPaises = this.itemsPaises.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPaises.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroEstados(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroEstados = this.itemsEstados.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroEstados.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroMunicipios(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroMunicipios = this.itemsMunicipios.filter(v => v.municipio.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroMunicipios.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtrEstadosCiviles(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroEstadosCiviles = this.itemsEstadosCiviles.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroEstadosCiviles.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroEscolaridad(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroEscolaridad = this.itmesEscolaridad.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroEscolaridad.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroReligiones(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroReligiones = this.itemsReligiones.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroReligiones.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroOcupaciones(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroOcupaciones = this.itemsOcupaciones.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroOcupaciones.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueAnimales(val, done) {

            if (val.length > 0) {
                if (!this.itmesAnimales.includes(val)) {
                    this.itmesAnimales.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroAnimales(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroAnimales = this.itmesAnimales.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroAnimales.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueZoonosis(val, done) {
            if (val.length > 0) {
                if (!this.itmesZoonosis.includes(val)) {
                    this.itmesZoonosis.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroZonosis(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroZoonosis = this.itmesZoonosis.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroZoonosis.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueActividades(val, done) {
            if (val.length > 0) {
                if (!this.itmesActividadesFisicas.includes(val)) {
                    this.itmesActividadesFisicas.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroActividades(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroActividadesFisicas = this.itmesActividadesFisicas.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroActividadesFisicas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueAlimentos(val, done) {
            if (val.length > 0) {
                if (!this.itmesIntolerancias.includes(val)) {
                    this.itmesIntolerancias.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroAlimentos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroAlimentos = this.itmesIntolerancias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroAlimentos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueAlergias(val, done) {
            if (val.length > 0) {
                if (!this.itmesAlergias.includes(val)) {
                    this.itmesAlergias.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroAlergias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroAlergias = this.itmesAlergias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroAlergias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroHemotipo(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroHemotipo = this.itmesHemotipos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroHemotipo.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroBebidas(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroBebidas = this.itmesBebidas.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroBebidas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroToxicomanias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroToxicomanias = this.itmesToxicomanias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroToxicomanias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        agregarActividad() {
            if (this.actividad.actividad == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una actividad' })
                return;
            }
            if (this.actividad.horas == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una cantidad de horas mayor a 0' })
                return;
            }
            if (this.actividad.horas < 0 || this.actividad.horas > 168) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'El número de horas debe estar entre 0 y 168' })
                this.actividad.horas = 0
                return;
            }
            this.$store.state.pacienteStore.antecPersonalesNoPatologicos.actividadesFisicas.push(this.actividad);

            this.actividad = {
                actividad: '',
                horas: 0
            }
        },

        eliminarActividad(data) {
            let editedIndex = this.$store.state.pacienteStore.antecPersonalesNoPatologicos.actividadesFisicas.indexOf(data)
            this.$store.state.pacienteStore.antecPersonalesNoPatologicos.actividadesFisicas.splice(editedIndex, 1)
        },

        agregarBebida() {
            if (this.bebida.bebida == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una bebida' })
                return;
            }
            if (this.bebida.frecuencia == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la frecuencia' })
                return;
            }
            if (this.bebida.numCopas == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el total de copas' })
                return;
            }
            if (this.bebida.numCopas < 0 || this.bebida.numCopas > 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'El total de copas debe estar entre 1 y 100' })
                this.bebida.numCopas = 0
                return;
            }
            this.$store.state.pacienteStore.antecPersonalesNoPatologicos.alcoholismo.push(this.bebida);

            this.bebida = {
                bebida: '',
                frecuencia: '',
                numCopas: '',
            }
        },

        eliminarBebida(data) {
            let editedIndex = this.$store.state.pacienteStore.antecPersonalesNoPatologicos.alcoholismo.indexOf(data)
            this.$store.state.pacienteStore.antecPersonalesNoPatologicos.alcoholismo.splice(editedIndex, 1)
        },

        agregarToxicomania() {
            if (this.toxi.toxicomania == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una toxicomanía' })
                return;
            }
            if (this.toxi.frecuencia == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la frecuencia' })
                return;
            }
            this.$store.state.pacienteStore.antecPersonalesNoPatologicos.toxicomanias.push(this.toxi);

            this.toxi = {
                toxicomania: '',
                frecuencia: ''
            }
        },

        eliminarToxicomania(data) {
            let editedIndex = this.$store.state.pacienteStore.antecPersonalesNoPatologicos.toxicomanias.indexOf(data)
            this.$store.state.pacienteStore.antecPersonalesNoPatologicos.toxicomanias.splice(editedIndex, 1)
        },

        validaComidasDia() {
            if (this.paciente.comidasDia < 1 && this.paciente.comidasDia < 10) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'El número de comidas al dia debe estar entre 1 y 10' })
                this.paciente.comidasDia = 1
                return;
            }
        },
        validaCalidad() {
            if (this.paciente.calidadAlimentacion < 0 && this.paciente.calidadAlimentacion < 5) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'La calidad de alimentación debe estar entre 0 y 5' })
                this.paciente.calidadAlimentacion = 0
                return;
            }
        },
        validaAñosBiomasa() {
            if (this.paciente.exposicionBiomasa.años < 0 && this.paciente.exposicionBiomasa.años < 5) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Años con exposición debe estar entre 0 y 100' })
                this.paciente.exposicionBiomasa.años = 0
                return;
            }
        },
        validaHorasBiomasa() {
            if (this.paciente.exposicionBiomasa.horas < 0 && this.paciente.exposicionBiomasa.horas < 24) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Horas de exposición debe estar entre 0 y 24' })
                this.paciente.exposicionBiomasa.horas = 0
                return;
            }
        },
        validaCigarrosDia() {
            if (this.paciente.tabaquismo.numCigarros < 0 && this.paciente.tabaquismo.numCigarros < 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Número de cigarros al día debe estar entre 0 y 100' })
                this.paciente.tabaquismo.numCigarros = 0
                return;
            }
        },
        validaAñosFumando() {
            if (this.paciente.tabaquismo.años < 0 && this.paciente.tabaquismo.años < 150) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Número de años fumando debe estar entre 0 y 150' })
                this.paciente.tabaquismo.años = 0
                return;
            }
        }
    },
}
</script>