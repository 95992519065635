<template>
    <q-page class=" q-pa-lg ">
        <!-- DIALOGO PARA CREAR UN NUEVO PACIENTE -->
        <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <Empleados @cierraVentana="dialogNuevo = false"></Empleados>
        </q-dialog>

        <div class="row	flex-left ">
            <!-- LOGO DE ONE ANALITICA -->
            <div class="col-12 col-md-6 col-sm-6 text-left ">
                <span v-if="$store.state.isDark == true"
                    class="q-pa-lg shadow-6 mb-3 inline-block surface-card logo-inicio"
                    style="border-radius: 10px; width: 230px; height: 50px;">
                </span>
                <span v-else class="q-pa-lg shadow-0 mb-3 inline-block surface-card logo-inicio-negro"
                    style=" width: 230px; height: 50px;">
                </span>
            </div>
            <!-- BOTONES DE ACCION -->
            <div class="col-12 col-md-6 col-sm-6 text-right ">
                <q-btn size="md" class="btn-estilos q-mr-sm" icon="mdi-plus" unelevated rounded
                    style="background:#1A4161; color:white" label="Nuevo Empleado" @click="nuevoEmpleado()" />
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-update"
                    @click="GetEmpleados()" />
            </div>
        </div>
        <div>
            <q-table title="Empleados" :data="items" :columns="columns" row-key="nombre" :filter="filter"
                :loading="loading" :pagination="pagination">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <template v-if="$store.state.isDark">
                                <q-btn size="md" style="color:#686868" rounded flat dense
                                    @click="editarEmpleado(props.row)" icon="mdi-pencil">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Editar</q-tooltip>
                                </q-btn>
                            </template>
                            <template v-if="!$store.state.isDark">
                                <q-btn size="md" style="color:#1A4161" rounded flat dense
                                    @click="editarEmpleado(props.row)" icon="mdi-pencil">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Editar</q-tooltip>
                                </q-btn>
                            </template>
                        </q-td>
                        <q-td key="username" :props="props">{{ props.row.username }}</q-td>
                        <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                        <q-td key="apellido" :props="props">{{ props.row.apellido }}</q-td>
                        <q-td key="fechaNacimiento" :props="props">{{ props.row.fechaNacimiento }}</q-td>
                        <q-td key="genero" :props="props">{{ props.row.genero }}</q-td>
                        <q-td key="telefono" :props="props">{{ props.row.telefono }}</q-td>
                        <q-td key="email" :props="props">{{ props.row.email }}</q-td>
                        <q-td key="group" :props="props">{{ props.row.group }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </q-page>
</template>
<script>

import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import Empleados from './Empleados.vue'
export default {
    components: { Empleados },
    data() {
        return {
            filter: '',
            loading: false,
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'username', align: 'center', label: 'Nombre de usuario', field: 'username', sortable: true },
                { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'apellido', align: 'center', label: 'Apellidos', field: 'apellido', sortable: true },
                { name: 'fechaNacimiento', align: 'center', label: 'Fecha de nacimiento', field: 'fechaNacimiento' },
                { name: 'genero', align: 'center', label: 'Genero', field: 'genero' },
                { name: 'telefono', align: 'center', label: 'Teléfono', field: 'telefono' },
                { name: 'email', align: 'center', label: 'Email', field: 'email', sortable: true },
                { name: 'group', align: 'center', label: 'Rol', field: 'group' },
            ],
            data: [],
            dialogNuevo: false,
            pagination: {
                sortBy: 'nombre',
                descending: false,
                page: 1,
                rowsPerPage: 10
                // rowsNumber: xx if getting data from a server
            },
            dialogConfirm: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaEmpleadosStore
        }
    },
    created() {
        this.GetEmpleados();
    },
    methods: {
        nuevoEmpleado() {
            let empleado = {
                _id: '',
                username: '',
                nombre: '',
                apellido: '',
                password: '',
                email: '',
                fechaNacimiento: new Date(),
                genero: '',
                status: 'active',
                telefono: '',
                group: ''
            }
            this.$store.state.empleadoStore = empleado
            this.dialogNuevo = true
        },
        async GetEmpleados() {
            this.loading = true
            try {
                let response = await axios.get("Login/GetEmpleados/dev");
                let catalogo = response.data;
                this.$store.state.listaEmpleadosStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },

        editarEmpleado(data) {
            console.log(data)
            data.password = ''
            this.$store.state.empleadoStore = { ...data }
            this.$store.state.indexEmpleado = this.$store.state.listaEmpleadosStore.indexOf(data)

            this.dialogNuevo = true
        },

    }
}
</script>

<style></style>
<style lang="sass">
    </style>