<template>
    <div class="row q-col-gutter-sm">

        <!-- PADECIMIENTO ACTUAL -->
        <q-dialog v-model="dialogPadecimientoActual" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Sintomas Padecimiento Actual</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select new-value-mode="add" v-model="padecimiento.sintoma" @filter="filtroSintomas"
                                :options="itemsfiltroSintomas" option-label="sintoma" use-input input-debounce="0"
                                hide-selected dense filled label="Síntomas" virtual-scroll-slice-size="1" fill-input />
                        </div>
                        <div class="col-12 col-md-2">
                            <q-input dense type="number" filled label="Intensidad" v-model="padecimiento.intensidad"
                                min="1" max="10">
                                <q-tooltip>
                                    Valor Numérico de 1 a 10
                                </q-tooltip>
                            </q-input>
                        </div>
                        <div class="col-12 col-md-10">
                            <q-select v-if="padecimiento.sintoma.localizacion == 'SI'" dense filled
                                v-model="padecimiento.partesCuerpo" use-input use-chips multiple input-debounce="0"
                                @new-value="createValuePartesCuerpo" :options="itemsfiltroPartesCuerpo"
                                @filter="filtroPartesCuerpo" label="Partes del cuerpo">
                            </q-select>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregar()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-dialog v-model="dialogDetalles" persistent transition-show="flip-down" transition-hide="flip-up">
            <q-card>
                <q-bar>
                    <div>{{ this.titulo }}</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section>
                    <q-table dense :data="detalles" :columns="columnsDetalles" row-key="descripcion" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <div class="col-12 col-md-2">
            <q-input v-model="fechaMasked" filled label="Fecha de Inicio" name="event" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" color="primary" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="paciente.fechaInicio">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>
        </div>

        <!-- <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>PADECIMIENTO ACTUAL</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-plus" @click="dialogPadecimientoActual = true">
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Padecimiento Actual</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Padecimiento"
                @click="dialogPadecimientoActual = true" />
            <div class="element-linea"></div>
        </div>
        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md" :data="paciente.sintomas"
                :columns="columns" :rows-per-page-options="[0]" separator="cell" no-data-label="Sin datos disponibles"
                row-key="sintoma">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense @click="eliminar(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="sintoma" :props="props">{{ props.row.sintoma }}
                        </q-td>
                        <q-td auto-width key="partesCuerpo">
                            <q-btn size="md" color="primary" rounded flat dense @click="verPartes(props.row)"
                                icon="mdi-format-list-bulleted">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Ver partes del cuerpo</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="intensidad" :props="props">
                            {{ props.row.intensidad }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Diagnósticos</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select v-model="paciente.diagnosticos" use-chips multiple @filter="filtroDiagnosticos"
                @new-value="createValueDiagnosticos" :options="itemsfiltroDiagnosticos" use-input input-debounce="0"
                dense filled label="Nombre de la enfermedad" virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-12">
            <q-input v-model="paciente.descripcion" label="Descripción del padecimiento " filled type="textarea" />
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsfiltroPartesCuerpo: null,
            itemsfiltroSintomas: null,
            itemsfiltroDiagnosticos: null,
            padecimiento: {
                sintoma: '',
                partesCuerpo: [],
                intensidad: 0
            },

            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'sintoma', align: 'center', label: 'Síntoma', field: 'sintoma', sortable: true },
                { name: 'partesCuerpo', align: 'center', label: 'Partes del cuerpo', field: 'partesCuerpo', sortable: true, },
                { name: 'intensidad', align: 'center', label: 'Intensidad del síntoma', field: 'intensidad', sortable: true, },
            ],

            dialogDetalles: false,
            detalles: [],
            titulo: '',
            columnsDetalles: [
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
            ],
            dialogPadecimientoActual: false
        }
    },
    computed: {
        paciente() {
            return this.$store.state.pacienteStore.padecimientoActual;
        },
        itemsPartesCuerpo() {
            return this.$store.state.listaCatalogoPartesCuerpo
        },
        itemsSintomas() {
            return this.$store.state.listaCatalogoSintomas
        },
        fechaMasked() {
            moment.locale('es-mx');
            return this.paciente.fechaInicio ? moment.utc(this.paciente.fechaInicio).format('DD/MMMM/yyyy') : ''
        },
        itemsDiagnosticosCIE() {
            return this.$store.state.listaCatalogoDiagnosticosCIE
        },
    },
    watch: {
    },
    created() {
    },
    methods: {
        filtroDiagnosticos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroDiagnosticos = this.itemsDiagnosticosCIE.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroDiagnosticos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroSintomas(val, update, abort) {
            let stringOptions = this.itemsSintomas
            if (val === '') {
                update(() => {
                    this.itemsfiltroSintomas = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.itemsfiltroSintomas = stringOptions.filter(v => v.sintoma.toLowerCase().indexOf(needle) > -1)
            })

            // update(() => {
            //     const needle = val.toLocaleLowerCase()
            //     this.itemsfiltroSintomas = this.itemsSintomas.filter(v => v.sintoma.toLocaleLowerCase().indexOf(needle) > -1)
            // },
            //     ref => {
            //         if (val !== '' && this.itemsfiltroSintomas.length > 0) {
            //             ref.setOptionIndex(-1)
            //             ref.moveOptionSelection(1, true)
            //         }
            //     })
        },
        filtroPartesCuerpo(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPartesCuerpo = this.itemsPartesCuerpo.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPartesCuerpo.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValuePartesCuerpo(val, done) {
            if (val.length > 0) {
                if (!this.itemsPartesCuerpo.includes(val)) {
                    this.itemsPartesCuerpo.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueDiagnosticos(val, done) {
            if (val.length > 0) {
                if (!this.itemsDiagnosticosCIE.includes(val)) {
                    this.itemsDiagnosticosCIE.push(val)
                }
                done(val, 'toggle')
            }
        },
        agregar() {
            if (this.padecimiento.sintoma == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un síntoma' })
                return;
            }
            if (this.padecimiento.partesCuerpo.length == 0 && this.padecimiento.sintoma.localizacion == 'SI') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique las partes del cuerpo' })
                return;
            }
            // if (this.padecimiento.intensidad == 0) {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la intensidad del síntoma' })
            //     return;
            // }
            if (this.padecimiento.intensidad < 0 || this.padecimiento.intensidad > 10) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'La intesidad del síntoma debe tener un valor entre 1 a 10' })
                this.padecimiento.intensidad = 0
                return;
            }
            this.padecimiento.sintoma = this.padecimiento.sintoma.sintoma

            this.$store.state.pacienteStore.padecimientoActual.sintomas.push(this.padecimiento);
            this.padecimiento = {
                sintoma: '',
                partesCuerpo: [],
                intensidad: 0
            }
        },
        eliminar(data) {
            let editedIndex = this.$store.state.pacienteStore.padecimientoActual.sintomas.indexOf(data)
            this.$store.state.pacienteStore.padecimientoActual.sintomas.splice(editedIndex, 1)
        },
        verPartes(data) {
            console.log(data);
            let lista = []
            for (let a of data.partesCuerpo) {
                let objeto = {
                    descripcion: a
                }
                lista.push(objeto)
            }
            this.detalles = lista
            this.dialogDetalles = true
            this.titulo = 'Partes del cuerpo'
        }
    }
}
</script>