<template>
	<q-card>
		<q-card-section>
			<q-toolbar>
				<!-- BOTON PARA CERRAR -->
				<q-btn round dense style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close"
					@click="cierraVentana()">
					<q-tooltip> Cerrar </q-tooltip>
				</q-btn>
				<q-toolbar-title> Consultorio </q-toolbar-title>
				<q-btn v-if="consultorio._id == ''" round dense style="background:#1A4161; color:white" class="q-mr-sm"
					icon="mdi-content-save" @click="PostConsultorio()">
					<q-tooltip> Guardar </q-tooltip>
				</q-btn>

				<q-btn v-else round dense style="background:#1A4161; color:white" class="q-mr-sm"
					icon="mdi-content-save" @click="PutConsultorio()">
					<q-tooltip> Editar </q-tooltip>
				</q-btn>
			</q-toolbar>
			<div class="element-linea"></div>
		</q-card-section>

		<q-card-section style="max-height: 700px; min-height: 400px" class="scroll">
			<div class="row q-col-gutter-sm">
				<!-- LIGA MAPS -->
				<div class="col-12">
					<q-input dense filled v-model="consultorio.nombre" label="Consultorio" />
				</div>
				<!-- PAIS -->
				<div class="col-12">
					<q-select v-model="consultorio.pais" emit-value map-options @filter="filtroPaises"
						:options="itemsfiltroPaises" use-input input-debounce="0" dense filled label="País"
						virtual-scroll-slice-size="1" />
				</div>
				<template v-if="consultorio.pais == 'MEXICO'">
					<!-- ESTADO -->
					<div class="col-12">
						<q-select v-model="consultorio.estado" emit-value map-options @filter="filtroEstados"
							:options="itemsfiltroEstados" use-input input-debounce="0" dense filled label="Estado"
							virtual-scroll-slice-size="1" @input="filtroMunicipiosPorEstado()" />
					</div>
					<!-- MUNICIPIO -->
					<div class="col-12">
						<q-select v-model="consultorio.municipio" emit-value map-options @filter="filtroMunicipios"
							option-label="municipio" :options="itemsfiltroMunicipios" use-input input-debounce="0" dense
							filled label="Municipio" virtual-scroll-slice-size="1" />
					</div>
				</template>
				<!-- COLONIA -->
				<div class="col-12">
					<q-input dense filled v-model="consultorio.colonia" label="Colonia" />
				</div>
				<!-- CALLE -->
				<div class="col-12">
					<q-input dense filled v-model="consultorio.calle" label="Calle" />
				</div>
				<!-- NUMERO EXTERIOR -->
				<div class="col-12">
					<q-input dense filled v-model="consultorio.numero_exterior" label="Número exterior" />
				</div>
				<!-- NUMERO INTERIOR -->
				<div class="col-12">
					<q-input dense filled v-model="consultorio.numero_interior" label="Número Interior" />
				</div>
				<!-- NUMERO DE CONSULTORIO -->
				<div class="col-12">
					<q-input dense filled v-model="consultorio.numero_consultorio" label="Número de Consultorio" />
				</div>
				<!-- REFERENCIA -->
				<div class="col-12">
					<q-input dense filled v-model="consultorio.referencia" label="Referencia" />
				</div>
				<!-- LIGA MAPS -->
				<div class="col-12">
					<q-input dense filled v-model="consultorio.liga_maps" label="Liga Maps" />
				</div>
			</div>
		</q-card-section>
	</q-card>
</template>
<script>
import axios from "axios";
import { QSpinnerCube } from "quasar";

export default {
	components: {},
	data() {
		return {
			itemsfiltroPaises: null,
			itemsfiltroEstados: null,
			itemsfiltroMunicipios: null,
			itemsMunicipios: [],
		};
	},
	computed: {
		consultorio() {
			return this.$store.state.consultorioStore;
		},

		itemsPaises() {
			return this.$store.state.listaCatalogoPaises;
		},

		itemsEstados() {
			return this.$store.state.listaCatalogoEstados;
		},

		itemsMunicipiosFiltrados() {
			return this.$store.state.listaCatalogoMunicipios;
		},
	},

	watch: {},

	created() { },

	methods: {
		async PostConsultorio() {
			let consultorio = { ...this.$store.state.consultorioStore };
			//VALIDAMOS
			if (!this.ValidaConsultorio(consultorio)) {
				return;
			}

			//DESPUES DE VALIDAR, GUARDAMOS
			try {
				this.$q.loading.show({
					spinner: QSpinnerCube,
					spinnerColor: "white",
					spinnerSize: 140,
					message: "Guardando Consultorio. Espere...",
					messageColor: "white",
				});

				let response = await axios.post("Catalogos/PostConsultorioAsync/dev/", consultorio);
				console.log(response.data);
				this.$q.notify({
					type: "positive",
					message: `Consultorio guardado con éxito`,
					actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },],
				});
				consultorio._id = response.data;
				this.$store.commit('postConsultorio', consultorio);
				this.$q.loading.hide();
				this.cierraVentana();
			} catch (error) {
				console.log(error);
				this.$q.notify({
					type: "negative",
					message: `Error al guardar, intente nuevamente y verifique que la infomración este completa`,
					actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },
					],
				});
				this.$q.loading.hide();
			}
		},

		async PutConsultorio() {
			let consultorio = { ...this.$store.state.consultorioStore };
			//VALIDAMOS
			if (!this.ValidaConsultorio(consultorio)) {
				return;
			}

			//DESPUES DE VALIDAR, GUARDAMOS
			try {
				this.$q.loading.show({
					spinner: QSpinnerCube,
					spinnerColor: "white",
					spinnerSize: 140,
					message: "Actualizando Consultorio. Espere...",
					messageColor: "white",
				});

				let response = await axios.post("Catalogos/PutConsultorioAsync/dev/", consultorio);
				console.log(response.data);
				this.$q.notify({
					type: "positive",
					message: `Consultorio actualizado con éxito`,
					actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },],
				});
				consultorio._id = response.data;
				this.$store.commit('putConsultorio', consultorio);
				this.$q.loading.hide();
				this.cierraVentana();
			} catch (error) {
				console.log(error);
				this.$q.notify({
					type: "negative",
					message: `Error al actualizar, intente nuevamente y verifique que la infomración este completa`,
					actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },
					],
				});
				this.$q.loading.hide();
			}
		},

		cierraVentana() {
			this.$emit("cierraVentana");
		},

		async ValidaConsultorio(consultorio) {
			//VALIDAMOS EL NOMBRE DEL CONSULTORIO
			if (consultorio.nombre === "") {
				this.$q.notify({
					type: "warning",
					message: `Indique el nombre del consultorio.`,
					actions: [{ label: "Cerrar", color: "white", handler: () => {/* ... */ }, },],
				});
				return false;
			}

			//VALIDAMOS EL PAIS
			if (consultorio.pais === "") {
				this.$q.notify({
					type: "warning",
					message: `Indique el país.`,
					actions: [{ label: "Cerrar", color: "white", handler: () => {/* ... */ }, },],
				});
				return false;
			}

			if (consultorio.pais === "MEXICO") {
				// VALIDAMOS EL ESTADO
				if (consultorio.estado === "") {
					this.$q.notify({
						type: "warning",
						message: `Indique el estado.`,
						actions: [{ label: "Cerrar", color: "white", handler: () => {/* ... */ }, },],
					});
					return false;
				}

				// VALIDAMOS EL MUNICIPIO
				if (!consultorio.municipio) {
					this.$q.notify({
						type: "warning",
						message: `Indique el municipio.`,
						actions: [{ label: "Cerrar", color: "white", handler: () => {/* ... */ }, },],
					});
					return false;
				}
			}

			//   VALIDAMOS LA COLONIA
			if (consultorio.colonia === "") {
				this.$q.notify({
					type: "warning",
					message: `Indique la colonia.`,
					actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },],
				});
				return false;
			}

			//   VALIDAMOS LA CALLE
			if (consultorio.calle === "") {
				this.$q.notify({
					type: "warning",
					message: `Indique la calle.`,
					actions: [{ label: "Cerrar", color: "white", handler: () => {/* ... */ }, },],
				});
				return false;
			}

			//   VALIDAMOS EL NUMERO EXTERIOR
			if (consultorio.numero_exterior === "") {
				this.$q.notify({
					type: "warning",
					message: `Indique el número exterior.`,
					actions: [{ label: "Cerrar", color: "white", handler: () => {/* ... */ }, },],
				});
				return false;
			}

			//   VALIDAMOS EL NUMERO DE CONSULTORIO
			if (consultorio.numero_consultorio === "") {
				this.$q.notify({
					type: "warning",
					message: `Indique el número de consultorio.`,
					actions: [{ label: "Cerrar", color: "white", handler: () => {/* ... */ }, },],
				});
				return false;
			}

			//   VALIDAMOS LA LIGA DE MAPS
			if (consultorio.liga_maps === "") {
				this.$q.notify({
					type: "warning",
					message: `Indique la liga de maps para localiza de una mejor manera el consultorio.`,
					actions: [{ label: "Cerrar", color: "white", handler: () => {/* ... */ }, },],
				});
				return false;
			}

			return true;
		},

		//FILTROS PARA LAS DIRECCIONES
		filtroPaises(val, update, abort) {
			update(
				() => {
					const needle = val.toLocaleLowerCase();
					this.itemsfiltroPaises = this.itemsPaises.filter(
						(v) => v.toLocaleLowerCase().indexOf(needle) > -1
					);
				},
				(ref) => {
					if (val !== "" && this.itemsfiltroPaises.length > 0) {
						ref.setOptionIndex(-1);
						ref.moveOptionSelection(1, true);
					}
				}
			);
		},

		filtroEstados(val, update, abort) {
			update(
				() => {
					const needle = val.toLocaleLowerCase();
					this.itemsfiltroEstados = this.itemsEstados.filter(
						(v) => v.toLocaleLowerCase().indexOf(needle) > -1
					);
				},
				(ref) => {
					if (val !== "" && this.itemsfiltroEstados.length > 0) {
						ref.setOptionIndex(-1);
						ref.moveOptionSelection(1, true);
					}
				}
			);
		},

		filtroMunicipios(val, update, abort) {
			update(
				() => {
					const needle = val.toLocaleLowerCase();
					this.itemsfiltroMunicipios = this.itemsMunicipios.filter(
						(v) => v.municipio.toLocaleLowerCase().indexOf(needle) > -1
					);
				},
				(ref) => {
					if (val !== "" && this.itemsfiltroMunicipios.length > 0) {
						ref.setOptionIndex(-1);
						ref.moveOptionSelection(1, true);
					}
				}
			);
		},

		filtroMunicipiosPorEstado() {
			this.itemsMunicipios = [];
			const needle = this.consultorio.estado;
			console.log(needle);
			console.log(this.itemsMunicipiosFiltrados);
			let municipios = this.itemsMunicipiosFiltrados.filter(
				(v) => v.estado.indexOf(needle) > -1
			);
			this.itemsMunicipios = [...municipios];
			console.log(municipios);
		},
	},
};
</script>
<style></style>
