<template>
    <q-card>
        <!-- DIALOG QUE GENERA CODIGOS, PARA AGREGAR A LA LISTA DE USUARIOS VISIBLES -->
        <q-dialog v-model="dialogGenerar" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 text-center">
                            <q-input filled v-model="codigoGenerado" label="Código para ser agregado a la agenda" dense
                                readonly>
                                <template v-slot:append>
                                    <q-btn icon="mdi-content-copy" flat @click="CopiarCodigo()">
                                        <q-tooltip> Copiar </q-tooltip>
                                    </q-btn>
                                </template>
                            </q-input>
                        </div>
                        <span>Al compartir el siguiente código, el usuario que lo capture, podrá acceder a la
                            infomración de su agenda, como a sus consultorios, pudiendo agregar, editar o eliminar,
                            según sea el caso </span>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA AGREGAR EL CODIGO DE LOS USUARIOS VISIBLES -->
        <q-dialog v-model="dialogAgregar" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12">
                            <q-input filled v-model="selectCodigo" label="Ingrese un código" dense
                                class="text-uppercase" />
                        </div>
                        <div class="col-12">
                            <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                                style="background:#1A4161; color:white" @click="PostRelacion()">
                                Agregar
                            </q-btn>
                        </div>
                        <span>Al agregar el siguiente código, podrá acceder a las citas y consultorios del usuario que
                            le proporciono el mismo</span>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>

        <q-card-section>
            <q-toolbar>
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close"
                    @click="cierraVentana()">
                    <q-tooltip> Cerrar </q-tooltip>
                </q-btn>
                <q-toolbar-title> Usuarios Relacionados </q-toolbar-title>
            </q-toolbar>
            <!-- <q-separator class="full-width" color="primary" inset size="4px" /> -->
            <div class="element-linea"></div>

        </q-card-section>
        <q-card-section style="max-height: 900px; min-height: 600px" class="scroll">
            <div class="row q-col-gutter-sm">
                <div class="col-12">
                    <q-table title="Usuarios" :data="itemsUsuarios" :columns="columns" row-key="usuario_principal"
                        :filter="filter" :pagination="pagination">
                        <template v-slot:top-right>
                            <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                                <template v-slot:append>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
                        </template>
                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td auto-width key="acciones">
                                    <template v-if="!$store.state.isDark">
                                        <q-btn size="md" style="color:#1A4161" rounded flat dense
                                            @click="DeleteUsuario(props.row)" icon="mdi-delete">
                                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                content-style="font-size: 14px">Eliminar</q-tooltip>
                                        </q-btn>
                                    </template>
                                    <template v-if="$store.state.isDark">
                                        <q-btn size="md" color="white" rounded flat dense
                                            @click="DeleteUsuario(props.row)" icon="mdi-delete">
                                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                content-style="font-size: 14px">Eliminar</q-tooltip>
                                        </q-btn>
                                    </template>
                                </q-td>
                                <q-td key="usuario_principal" :props="props">{{ props.row.usuario_principal }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </div>
                <div class="col-12">
                    <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                        style="background:#1A4161; color:white" @click="OpenDialogAgregarCodigo()">
                        Agregar Código
                    </q-btn>
                </div>
                <div class="col-12">
                    <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                        style="background:#1A4161; color:white" @click="OpenDialogGenerarCodigo()">
                        Ver Código de Usuario
                    </q-btn>
                </div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';

export default {
    components: {

    },

    data() {
        return {
            dialogAgregar: false,
            selectCodigo: '',

            dialogGenerar: false,
            codigoGenerado: '',

            //USUARIOS YA ASIGNADOS
            columns: [
                { name: "acciones", align: "center", label: "Acciones", field: "acciones", },
                { name: "usuario_principal", align: "center", label: "Usuario", field: "usuario_principal", sortable: true, },
            ],
            itemsUsuarios: [],
            filter: "",
            pagination: {
                sortBy: "usuario_principal",
                descending: false,
                page: 1,
                rowsPerPage: 10,
            },
        }
    },

    computed: {
        token() {
            return this.$store.state.usuario;
        },

        usuarioActual() {
            return this.$store.state.realcionUserStore;
        },

    },

    watch: {
    },

    created() {
        this.GetUsuariosRegistrados()
    },

    methods: {
        //OBTENEMOS LOS USUARIOS YA ASIGNADOS
        async GetUsuariosRegistrados() {
            this.itemsUsuarios = [];
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Consultando. Espere...', messageColor: 'white' })
            try {
                let userA = this.usuarioActual;
                let response = await axios.get("Agenda/GetUsuariosRelacionadosAsync/dev/" + userA._id);
                this.itemsUsuarios = response.data;
                this.$q.loading.hide()
            } catch (error) {
                this.$q.loading.hide()
            }
        },

        async DeleteUsuario(item) {
            let _id = item._id;
            let indice = this.itemsUsuarios.findIndex(objeto => objeto._id === _id);
            console.log(_id, indice)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Quitando usuario de la lista.', messageColor: 'white' })
            try {
                let response = await axios.delete("Agenda/DeleteCodigoUsuarioAsync/dev/" + _id);
                this.$q.notify({
                    type: 'positive',
                    message: 'Usuario eliminado con éxito',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                this.itemsUsuarios.splice(indice, 1);
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.notify({
                    type: 'negative',
                    message: "Error al eliminar, intente nuevamente",
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                this.$q.loading.hide()
            }
        },

        //OBTENER Y MOSTRAR CODIGO
        async OpenDialogGenerarCodigo() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Consultando. Espere...', messageColor: 'white' })
            let Codigo = await this.GetCodigo();
            // SI NO HAY CODIGO REGISTRADO PREVIAMENTE, CREAMOS UN NUEVO CODIGO Y LO REGISTRAMOS
            if (Codigo === "") {
                let CodigoNuevo = await this.FuncionGeneraCodigo(6);
                Codigo = CodigoNuevo;
                let insert = await this.PostCodigoNuevo(Codigo);
                if (!insert) {
                    this.$q.loading.hide()
                    return;
                }
            }
            this.$q.loading.hide()
            this.codigoGenerado = "";
            this.codigoGenerado = Codigo;
            this.dialogGenerar = true;
        },

        async GetCodigo() {
            try {
                let userA = this.usuarioActual;
                let response = await axios.get("Agenda/GetCodigoAsync/dev/" + userA._id);
                return response.data.codigo;
            } catch (error) {
                console.log(error);
                return "";
            }
        },

        async FuncionGeneraCodigo(longitud) {
            const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let codigo = '';
            for (let i = 0; i < longitud; i++) {
                const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
                codigo += caracteres[indiceAleatorio];
            }
            return codigo;
        },

        async PostCodigoNuevo(Codigo) {
            try {
                let userA = this.usuarioActual;
                let objCodigo = {
                    _id: "",
                    id_usuario_principal: userA._id,
                    usuario_principal: userA.username,
                    codigo: Codigo,
                    fecha_creacion: new Date(),
                }
                let response = await axios.post("Agenda/PostCodigoAsync/dev/", objCodigo)
                this.$q.notify({ type: 'positive', message: `Código generado y guardado exitosamente.` })
                return true;
            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su conexión e intente nuevamente.', color: 'red' })
                return false;
            }
        },

        CopiarCodigo() {
            const elementoTemporal = document.createElement('textarea');
            elementoTemporal.value = this.codigoGenerado;
            document.body.appendChild(elementoTemporal);
            elementoTemporal.select();
            document.execCommand('copy');
            document.body.removeChild(elementoTemporal);
            this.$q.notify({ type: 'positive', message: `Código copiado` })
        },

        //REGISTRAR CODIGO
        async OpenDialogAgregarCodigo() {
            this.selectCodigo = "";
            this.dialogAgregar = true;
        },

        async PostRelacion() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando. Espere...', messageColor: 'white' })
            //PRIMERO VALIDAMOS
            if (this.selectCodigo === "") {
                this.$q.notify({
                    type: 'warning',
                    message: 'Indique un código',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            if (this.selectCodigo.length != 6) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Indique un código válido',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            //LUEGO HACEMOS EL POST
            try {
                let userA = this.usuarioActual;
                let ObjRelacion = {
                    _id: "",
                    id_usuario_principal: "",
                    usuario_principal: "",
                    id_usuario_relacionado: userA._id,
                    usuario_relacionado: userA.username,
                    codigo: this.selectCodigo,
                    fecha_creacion: new Date(),
                }

                let response = await axios.post("Agenda/PostCodigoUsuarioAsync/dev/", ObjRelacion)
                this.$q.notify({
                    type: 'positive',
                    message: 'Usuario agregado correctamente',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                this.selectCodigo = "";
                await this.GetUsuariosRegistrados();
                this.$q.loading.hide();
            } catch (error) {
                console.log(error);
                this.$q.notify({
                    type: 'negative',
                    message: error.response.data,
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                this.$q.loading.hide()
            }

        },

        //PARA CERRAR EL DIALOG
        cierraVentana() {
            this.$emit("cierraVentana");
        },

    }
}
</script>