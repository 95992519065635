<template>
    <div class="bg-primary window-height window-width row justify-center items-center">

        <div class="column">
            <div class="row">
                <!-- FORMULARIO PARA INICIAR SESION -->
                <q-card square bordered class="q-pa-lg shadow-1">
                    <q-card-section>
                        <q-form class="text-center">
                            <img alt="Contago logo" src="../assets/logo_black.png" style="height: 70px">
                        </q-form>
                    </q-card-section>
                    <q-card-section>
                        <q-form class="q-gutter-md">
                            <span style="color: aquamarine;">{{ this.$store.state.usuarioStore }}</span>

                            <!-- <img alt="Contago logo" src="../assets/logo_contago_sin_fondo.png" style="height: 100px"> -->
                            <q-input square filled v-model="nombreU" type="usuario" label="Usuario" />
                            <q-input square filled v-model="password" type="password" label="Contraseña"
                                @keyup.enter="validarUsuario()" />
                        </q-form>
                    </q-card-section>
                    <q-card-actions class="q-px-md">
                        <q-btn unelevated color="primary" size="lg" class="full-width" @click="validarUsuario()"
                            label="INICIAR SESIÓN" />
                    </q-card-actions>
                </q-card>
            </div>
        </div>

    </div>

</template>

<script>
import axios from 'axios';
import { QSpinnerGears } from 'quasar';
export default {
    name: 'Login',
    data() {
        return {
            password: '',
            nombreU: "",
        }
    },
    computed: {
    },
    methods: {
        async validarUsuario() {
            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese un nombre de usuario.', timeout: 2000 })
                return
            }
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese su contraseña.', timeout: 2000 })
                return
            }
            this.$q.loading.show({ message: '<b>Validando usuario...</b>' })
            try {
                let response = await axios.get(`Login/ValidaLogin/dev/${this.nombreU}/${this.password}`);
                this.$q.loading.hide();

                let data = response.data;
                // console.log(data)
                this.$store.state.usuario = { ...data.usuario };
                console.log(data.token);
                this.$store.dispatch("guardarToken", data.token);
                this.$router.push({ name: "Home" });

                this.nombreU = "";
                this.password = "";
            } catch (err) {
                console.log(err);
                this.$q.notify({ type: 'negative', message: err.response.data });
                this.$q.loading.hide();
            }
            // let respuesta = await axios.get("Login/ValidaLogin/dev/" + this.nombreU + "/" + this.password).then((response) => {
            //     this.$q.loading.hide()
            //     return response.data;
            // })
            //     .then((data) => {
            //         // console.log(data)
            //         this.$store.state.usuario = { ...data.usuario };
            //         this.$q.loading.hide()
            //         console.log(data.token)
            //         this.$store.dispatch("guardarToken", data.token);
            //         this.$router.push({ name: "Home" });

            //         this.nombreU = "";
            //         this.password = "";
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //         this.$q.notify({ type: 'negative', message: err.response.data })
            //         this.$q.loading.hide()
            //     });
        }
    }
}
</script>

<style>
.q-card {
    width: 500px;
}
</style>
