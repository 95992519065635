<template>
    <div class="row q-col-gutter-sm">
        <!-- <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>ANTECEDENTES GINECO OBSTÉTRICOS</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12  row">
            <div class="doc-h2">Antecedentes Gineco Obstétricos</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-2">
            <q-select dense v-model="paciente.activa" class="full-width" filled :options="['SI', 'NO']"
                label="Activa sexualmente" />
        </div>
        <div class="col-12 col-md-2">
            <q-input v-model="paciente.edadMenarca" min="0" max="100" dense type="number" filled label="Edad de menarca"
                @change="validadEdadMemarca()">
                <q-tooltip>
                    Valor Numérico de 1 a 100
                </q-tooltip>
            </q-input>
        </div>
        <div class="col-12 col-md-2">
            <q-select v-model="paciente.estatusMenopausia" dense class="full-width" filled
                :options="['PREMENOPAÚSICA', 'POSTMENOPAÚSICA']" label="Estatus de menopausia" />
        </div>
        <div class="col-12 col-md-3">
            <q-input v-model="fechaUltimaMasked" filled label="Fecha de Ultima menstruación" name="event" outlined
                dense>
                <template v-slot:before>
                    <q-icon name="event" color="primary" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="paciente.fechaUltimaMenstruacion">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>
        </div>

        <template v-if="paciente.activa == 'SI'">
            <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Activa Sexualmente</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
            <div class="col-12 col-md-2">
                <q-input v-model="paciente.actividadSexual.edadVidaSexual" min="0" max="100" dense type="number" filled
                    label="Edad de inicio de vida sexual" @change="validadVidaSexual">
                    <q-tooltip>
                        Valor Numérico de 1 a 100
                    </q-tooltip>
                </q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input v-model="paciente.actividadSexual.numGestas" min="0" max="100" dense type="number" filled
                    label="Número de gestas" @change="validaNumeroGestas">
                    <q-tooltip>
                        Valor Numérico de 1 a 100
                    </q-tooltip>
                </q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input v-model="paciente.actividadSexual.numPartos" min="0" max="100" dense type="number" filled
                    label="Número de partos" @change="vlaidaPartos">
                    <q-tooltip>
                        Valor Numérico de 1 a 100
                    </q-tooltip>
                </q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input v-model="paciente.actividadSexual.numCesareas" min="0" max="100" dense type="number" filled
                    label="Número de cesáreas" @change="validaCesareas">
                    <q-tooltip>
                        Valor Numérico de 1 a 100
                    </q-tooltip>
                </q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input v-model="paciente.actividadSexual.numAbortos" min="0" max="100" dense type="number" filled
                    label="Número de abortos" @change="validaAbortos">
                    <q-tooltip>
                        Valor Numérico de 1 a 100
                    </q-tooltip>
                </q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input v-model="paciente.actividadSexual.numParejas" min="0" max="100" dense type="number" filled
                    label="Número de parejas sexuales" @change="validaParejas">
                    <q-tooltip>
                        Valor Numérico de 1 a 100
                    </q-tooltip>
                </q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-select v-model="paciente.actividadSexual.orientacion" dense class="full-width" filled
                    :options="['HETEROSEXUAL', 'HOMOSEXUAL']" label="Orientacion sexual" />
            </div>
            <div class="col-12 col-md-3">
                <q-select v-model="paciente.actividadSexual.practicasRiesgo" dense class="full-width" filled
                    :options="['SI', 'NO']" label="¿Prácticas sexuales de riesgo?" />
            </div>
            <div class="col-12 col-md-7">
                <q-select new-value-mode="add" v-model="paciente.actividadSexual.mpf" emit-value map-options
                    @filter="filtroMPF" :options="itemsfiltroMPF" use-input input-debounce="0" dense filled
                    label="Método de planificación familiar" virtual-scroll-slice-size="1" />
            </div>
            <!-- <div class="col-12 q-mt-xl">
                <q-bar dense class="bg-primary text-white">
                    <q-space />
                    <div>ENFERMEDADES DE TRANSMISIÓN SEXUAL</div>
                    <q-space />
                </q-bar>
            </div> -->
            <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Enfermedades de Transmisión Sexual</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
            <div class="col-12 col-md-12">
                <q-select filled v-model="paciente.actividadSexual.enfermedadesTS" dense use-input use-chips multiple
                    input-debounce="0" @new-value="createValueETS" :options="itemsfiltroETS" @filter="filtroETS"
                    label="ETS" />
            </div>
        </template>
        <template v-if="paciente.estatusMenopausia == 'PREMENOPAÚSICA'">
            <div class="col-12 q-mt-xl">
                <q-bar dense class="bg-primary text-white">
                    <q-space />
                    <div>PREMENOPAÚSICA</div>
                    <q-space />
                </q-bar>
            </div>
            <div class="col-12 col-md-4">
                <q-select v-model="paciente.menopausia.cicloMenstrual" dense class="full-width" filled
                    :options="['REGULARES', 'IRREGULARES']" label="Ciclos menstruales" />
            </div>
            <div class="col-12 col-md-4">
                <q-input v-model="paciente.menopausia.frecuencia" dense type="number" filled min="1" max="90"
                    label="Frecuencia de los ciclos" @change="validaFrecuencia">
                    <q-tooltip>
                        Valor Numérico de 1 a 90
                    </q-tooltip>
                </q-input>
            </div>
            <div class="col-12 col-md-4">
                <q-input v-model="paciente.menopausia.diasSangrado" dense type="number" filled label="Días de sangrado"
                    min="1" max="10" @change="validaDiasSangrado">
                    <q-tooltip>
                        Valor Numérico de 1 a 10
                    </q-tooltip>
                </q-input>
            </div>
        </template>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsfiltroETS: null,
            itemsfiltroMPF: null,
        }
    },
    computed: {
        paciente() {
            return this.$store.state.pacienteStore.antecGinecoObstetricos;
        },
        itemsETS() {
            return this.$store.state.listaCatalogoETS
        },
        itemsMPF() {
            return this.$store.state.listaCatalogoMPF
        },
        fechaUltimaMasked() {
            moment.locale('es-mx');
            return this.paciente.fechaUltimaMenstruacion ? moment.utc(this.paciente.fechaUltimaMenstruacion).format('DD/MMMM/yyyy') : ''
        },
    },
    watch: {
    },
    created() {
    },
    methods: {
        filtroETS(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroETS = this.itemsETS.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroETS.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroMPF(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroMPF = this.itemsMPF.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroMPF.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        createValueETS(val, done) {
            if (val.length > 0) {
                if (!this.itmesETS.includes(val)) {
                    this.itmesETS.push(val)
                }
                done(val, 'toggle')
            }
        },

        validadEdadMemarca() {
            if (this.paciente.edadMenarca < 1 || this.paciente.edadMenarca > 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Edad menarca valir numérico de 1 a 100' })
                this.paciente.edadMenarca = 0
                return;
            }
        },

        validadVidaSexual() {
            if (this.paciente.actividadSexual.edadVidaSexual < 1 || this.paciente.actividadSexual.edadVidaSexual > 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Edad de inicio de vida sexual valor numérico de 1 a 100' })
                this.paciente.actividadSexual.edadVidaSexual = 0
                return;
            }
        },

        validaNumeroGestas() {
            if (this.paciente.actividadSexual.numGestas < 1 || this.paciente.actividadSexual.numGestas > 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Número de gestas entre 1 a 100' })
                this.paciente.actividadSexual.numGestas = 0
                return;
            }
        },

        vlaidaPartos() {
            if (this.paciente.actividadSexual.numPartos < 1 || this.paciente.actividadSexual.numPartos > 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Número de partos entee 1 a 100' })
                this.paciente.actividadSexual.numPartos = 0
                return;
            }
        },

        validaCesareas() {
            if (this.paciente.actividadSexual.numCesareas < 1 || this.paciente.actividadSexual.numCesareas > 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Número de cesáreas entre 1 a 100' })
                this.paciente.actividadSexual.numCesareas = 0
                return;
            }
        },

        validaAbortos() {
            if (this.paciente.actividadSexual.numAbortos < 1 || this.paciente.actividadSexual.numAbortos > 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Número de abortos entre 1 a 100' })
                this.paciente.actividadSexual.numAbortos = 0
                return;
            }
        },

        validaParejas() {
            if (this.paciente.actividadSexual.numParejas < 1 || this.paciente.actividadSexual.numParejas > 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Número de parejas sexuales entre 1 a 100' })
                this.paciente.actividadSexual.numParejas = 0
                return;
            }
        },

        validaFrecuencia() {
            if (this.paciente.menopausia.frecuencia < 1 || this.paciente.menopausia.frecuencia > 90) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Frecuencia de ciclos entre 1 a 90' })
                this.paciente.menopausia.frecuencia = 0
                return;
            }
        },

        validaDiasSangrado() {
            if (this.paciente.menopausia.diasSangrado < 1 || this.paciente.menopausia.diasSangrado > 10) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Días de sangrado valor numérico de 1 a 10' })
                this.paciente.menopausia.diasSangrado = 0
                return;
            }
        }
    }
}
</script>