<template>
    <div class="row q-col-gutter-sm">
        <div class="col-12">
            <q-bar style="background:#1A4161; color:white">
                <q-space />
                <div>FICHA DE IDENTIFICACÍON</div>
                <q-space />
            </q-bar>
        </div>
        <div class="col-12 ">
            <h2 class="doc-h2">Datos Generales</h2>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-5">
            <q-input v-model="paciente.apellidos" dense filled label="Apellidos del paciente" />
        </div>
        <div class="col-12 col-md-4">
            <q-input v-model="paciente.nombre" dense filled label="Nombre (s) del paciente" />
        </div>
        <div class="col-12 col-md-3">
            <q-input v-model="fehaIMasked" filled label="Fecha de nacimiento" name="event" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" color="primary" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="paciente.fechaNacimiento">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>
        </div>
        <!-- <div class="col-12 col-md-2">
            <q-input dense type="number" v-model="paciente.edad" filled label="Edad" />
        </div> -->
        <div class="col-12 col-md-3">
            <q-select dense class="full-width" v-model="paciente.genero" filled :options="itemsGenero" label="Genero" />
        </div>
        <!-- <div class="col-12 col-md-2">
            <q-select dense class="full-width" v-model="paciente.vive" filled :options="['Si', 'No']" label="¿Vive?" />
        </div> -->
        <div class="col-12 col-md-6">
            <q-select v-model="paciente.seguroMedico" emit-value map-options @filter="filtroAseguradoras"
                :options="itemsfiltroAseguradoras" use-input input-debounce="0" dense filled label="Seguro Medico"
                virtual-scroll-slice-size="1" new-value-mode="add" />
        </div>

        <div class="col-12 col-md-3">
            <q-input dense type="number" v-model="paciente.numPoliza" filled label="Número de Póliza" />
        </div>
        <div class="col-12 col-md-6">
            <q-input dense v-model="paciente.especialidad" filled label="Especialidad Médica de la consulta" />
        </div>
        <div class="col-12 col-md-6">
            <q-input dense filled label="Referencia" v-model="paciente.referencia" />
        </div>
        <!-- <div class="col-12 q-mt-lg">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>DATOS DEL CONTACTO</div>
                <q-space />
            </q-bar>
        </div> -->

        <div class="col-12 ">
            <h2 class="doc-h2">Datos del Contacto</h2>
            <div class="element-linea"></div>
        </div>

        <div class="col-12 col-md-4">
            <q-select v-model="paciente.datosContacto.pais" emit-value map-options @filter="filtroPaises"
                :options="itemsfiltroPaises" use-input input-debounce="0" dense filled label="País"
                virtual-scroll-slice-size="1" />
        </div>
        <div v-show="paciente.datosContacto.pais == 'MEXICO'" class="col-12 col-md-3">
            <q-select v-model="paciente.datosContacto.estado" emit-value map-options @filter="filtroEstados"
                :options="itemsfiltroEstados" use-input input-debounce="0" dense filled label="Estado"
                virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-3">
            <q-input dense filled label="Colonia" v-model="paciente.datosContacto.colonia" />
        </div>
        <div class="col-12 col-md-2">
            <q-input dense filled label="Código Postal" v-model="paciente.datosContacto.codigoPostal" />
        </div>
        <div class="col-12 col-md-3">
            <q-input dense filled label="Calle" v-model="paciente.datosContacto.calle" />
        </div>
        <div class="col-12 col-md-2">
            <q-input dense filled label="Número interior" v-model="paciente.datosContacto.numInterior" />
        </div>
        <div class="col-12 col-md-2">
            <q-input dense filled label="Número exterior" v-model="paciente.datosContacto.numExterior" />
        </div>
        <div class="col-12 col-md-4">
            <q-input v-model="paciente.datosContacto.celular" dense filled label="Celular" mask="(###) ### - ####"
                unmasked-value />
        </div>
        <div class="col-12 col-md-4">
            <q-input v-model="paciente.datosContacto.correo" dense filled label="Correo elctrónico"
                @change="validarCorreo()" id="email" name="email" />
        </div>
        <!-- <div class="col-12 q-mt-lg">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>PRIMER CONTACTO DE EMERGENCIA</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12 ">
            <h2 class="doc-h2">Primer Contacto de Emergencia</h2>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-6">
            <q-select v-model="paciente.contactoUrgencia.pais" emit-value map-options @filter="filtroPaises"
                :options="itemsfiltroPaises" use-input input-debounce="0" dense filled label="País"
                virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-6">
            <q-input dense filled label="Nombre Completo" v-model="paciente.contactoUrgencia.nombre" />
        </div>
        <div class="col-12 col-md-6">
            <q-input dense filled label="Correo elctrónico" v-model="paciente.contactoUrgencia.correo"
                @change="validarCorreoUrgencia()" />
        </div>
        <div class="col-12 col-md-6">
            <q-input dense filled label="Celular" v-model="paciente.contactoUrgencia.celular" mask="(###) ### - ####"
                unmasked-value />
        </div>
        <!-- <div class="col-12 q-mt-lg">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>SEGUNDO CONTACTO DE EMERGENCIA</div>
                <q-space />
            </q-bar>
        </div> -->
        <div class="col-12 ">
            <h2 class="doc-h2">Segundo Contacto de Emergencia</h2>
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-6">
            <q-select v-model="paciente.contactoResponsable.pais" emit-value map-options @filter="filtroPaises"
                :options="itemsfiltroPaises" use-input input-debounce="0" dense filled label="País"
                virtual-scroll-slice-size="1" />
        </div>
        <div class="col-12 col-md-6">
            <q-input dense filled label="Nombre Completo" v-model="paciente.contactoResponsable.nombre" />
        </div>
        <div class="col-12 col-md-6">
            <q-input dense filled label="Correo elctrónico" v-model="paciente.contactoResponsable.correo"
                @change="validarCorreoResponsable()" />
        </div>
        <div class="col-12 col-md-6">
            <q-input dense filled label="Celular" v-model="paciente.contactoResponsable.celular" mask="(###) ### - ####"
                unmasked-value />
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsGenero: ['Femenino', 'Masculino'],
            itemsfiltroAseguradoras: null,
            itemsfiltroEstados: null,
            itemsfiltroPaises: null,
            aseguradora: '',
            pais: '',
        }
    },
    computed: {
        paciente() {
            return this.$store.state.pacienteStore;
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.paciente.fechaNacimiento ? moment.utc(this.paciente.fechaNacimiento).format('DD/MMMM/yyyy') : ''
        },
        itemsAseguradoras() {
            return this.$store.state.listaCatalogoAseguradoras
        },
        itemsPaises() {
            return this.$store.state.listaCatalogoPaises
        },
        itemsEstados() {
            return this.$store.state.listaCatalogoEstados
        },
    },
    watch: {
    },
    created() {
    },
    methods: {
        filtroAseguradoras(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroAseguradoras = this.itemsAseguradoras.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroAseguradoras.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroEstados(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroEstados = this.itemsEstados.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroEstados.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroPaises(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPaises = this.itemsPaises.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPaises.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        validarCorreo() {
            var correoInput = this.paciente.datosContacto.correo;
            var correoRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (correoRegex.test(correoInput)) {
                // alert('Correo electrónico válido');
                // Aquí puedes realizar más acciones si el correo es válido
            } else {
                alert('Correo electrónico no válido. Por favor, introduce un correo válido.');
            }
        },
        validarCorreoUrgencia() {
            var correoInput = this.paciente.contactoUrgencia.correo
            var correoRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (correoRegex.test(correoInput)) {
                // alert('Correo electrónico válido');
                // Aquí puedes realizar más acciones si el correo es válido
            } else {
                alert('Correo electrónico no válido. Por favor, introduce un correo válido.');
            }
        },
        validarCorreoResponsable() {
            var correoInput = this.paciente.contactoResponsable.correo
            var correoRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (correoRegex.test(correoInput)) {
                // alert('Correo electrónico válido');
                // Aquí puedes realizar más acciones si el correo es válido
            } else {
                alert('Correo electrónico no válido. Por favor, introduce un correo válido.');
            }
        }
    }
}
</script>