<template>
    <div class="row q-col-gutter-sm">

        <!-- ENFERMEDADES CRÓNICO DEGENERATIVAS -->
        <q-dialog v-model="dialogEnfermedadesCronicoDegenerativas" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Enfermedades Crónico Degenerativas</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-10">
                            <q-select new-value-mode="add" v-model="enfermedad.diagnostico" emit-value map-options
                                @filter="filtroEnfermedades" :options="itemsfiltroEnfermedades" use-input
                                input-debounce="0" dense filled label="Enfermedad Crónico"
                                virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-2">
                            <q-input dense type="number" min="1" max="100" filled label="Años de evolución"
                                v-model="enfermedad.añosEvolucion">
                                <q-tooltip>
                                    Valor numérico entre 1 y 100
                                </q-tooltip>
                            </q-input>
                        </div>
                        <div class="col-12 col-md-12">
                            <q-select dense filled v-model="enfermedad.tratamientos" use-input use-chips multiple
                                input-debounce="0" @new-value="createValueTratamiento" :options="itemsfiltroTratamiento"
                                @filter="filtroTratamiento" label="Tratamiento">
                                <!-- <template v-slot:after>
                    <q-btn round color="primary" icon="mdi-plus" @click="agregarEnfermedad()">
                        <q-tooltip>
                            Agregar
                        </q-tooltip>
                    </q-btn>
                </template> -->
                            </q-select>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarEnfermedad()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- PROCEDIMIENTOS QUIRÚRGICOS PREVIOS -->
        <q-dialog v-model="dialogProcedimientosQuirurgicosPrevios" persistent>
            <q-card style="min-width: 1000px;">
                <q-bar class="bg-primary text-white">
                    <div>Procedimientos Quirúrgicos Previos</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select v-model="procedimiento.procedimiento" emit-value map-options
                                @filter="filtroProcedimientos" :options="itemsfiltroProcedimiento" use-input
                                input-debounce="0" dense filled label="Procedimiento" virtual-scroll-slice-size="1" />
                        </div>
                        <div class="col-12 col-md-3">
                            <q-input v-model="fehaProcedimiento" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="procedimiento.fecha">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                                <!-- <template v-slot:after>
                    <q-btn round color="primary" icon="mdi-plus" @click="agregarProcedimiento()">
                        <q-tooltip>
                            Agregar
                        </q-tooltip>
                    </q-btn>
                </template> -->
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarProcedimiento()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- TRAUMATISMOS PREVIOS -->
        <q-dialog v-model="dialogTraumatismosPrevios" persistent>
            <q-card style="min-width: 1300px;">
                <q-bar class="bg-primary text-white">
                    <div>Traumatismos Previos</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select v-model="traumatismo.tipo" new-value-mode="add" emit-value map-options
                                @filter="filtroTraumatismos" :options="itemsfiltroTraumatismos" use-input
                                input-debounce="0" dense filled label="Tipo de traumatismo"
                                virtual-scroll-slice-size="1" />
                        </div>

                        <div class="col-12 col-md-9">
                            <q-select dense filled v-model="traumatismo.fracturas.fracturas" use-input use-chips
                                multiple input-debounce="0" @new-value="createValueFracturas"
                                :options="itemsfiltroFracturas" @filter="filtroFracturas" label="Sitio de Lesión">
                            </q-select>
                        </div>
                        <div class="col-12 col-md-3">
                            <q-input v-model="fehaFractura" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="traumatismo.fracturas.fecha">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input>
                        </div>
                        <div class="col-12 col-md-9">
                            <q-select dense filled v-model="traumatismo.articulaciones.articulaciones" use-input
                                use-chips multiple input-debounce="0" @new-value="createValueArticulaciones"
                                :options="itemsfiltroArticulaciones" @filter="filtroArticulaciones"
                                label="Articulaciones">
                            </q-select>
                        </div>
                        <div class="col-12 col-md-3">
                            <q-input v-model="fehaArticulaciones" filled label="Fecha" name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="traumatismo.articulaciones.fecha">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input>
                        </div>
                        <div class="col-12 col-md-3">
                            <q-input v-model="fehaIMasked" filled label="Traumatismo craneoncefálico/medular"
                                name="event" outlined dense>
                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="traumatismo.fechaTraumatismo">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                                <!-- <template v-slot:after>
                    <q-btn round color="primary" icon="mdi-plus" @click="agregarTraumatismo()">
                        <q-tooltip>
                            Agregar
                        </q-tooltip>
                    </q-btn>
                </template> -->
                            </q-input>
                        </div>

                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn color="primary" flat label="CERRAR" v-close-popup />
                    <q-btn color="primary" flat label="AGREGAR" @click="agregarTraumatismo()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-dialog v-model="dialogDetalles" persistent transition-show="flip-down" transition-hide="flip-up">
            <q-card>
                <q-bar>
                    <div>{{ this.titulo }}</div>

                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section>
                    <q-table dense :data="detalles" :columns="columnsDetalles" row-key="descripcion" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <div class="col-12">
            <q-bar style="background:#1A4161; color:white">
                <q-space />
                <div>ANTECEDENTES PERSONALES PATOLÓGICOS</div>
                <q-space />
            </q-bar>
        </div>
        <!-- <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>ENFERMEDADES CRÓNICO DEGENERATIVAS</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-plus" @click="dialogEnfermedadesCronicoDegenerativas = true">
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Enfermedades Crónico Degenerativas</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Enfermedad"
                @click="dialogEnfermedadesCronicoDegenerativas = true" />
            <div class="element-linea"></div>
        </div>

        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="paciente.enfermedadesCronicos" :columns="columns" :rows-per-page-options="[0]" separator="cell"
                no-data-label="Sin datos disponibles" row-key="diagnostico">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarEnfermedad(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="diagnostico" :props="props">{{ props.row.diagnostico }}
                        </q-td>
                        <q-td key="añosEvolucion" :props="props">
                            {{ props.row.añosEvolucion }}
                        </q-td>
                        <q-td auto-width key="tramientos">
                            <q-btn size="md" color="primary" rounded flat dense @click="verTratamientos(props.row)"
                                icon="mdi-format-list-bulleted">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Ver Tratamientos</q-tooltip>
                            </q-btn>
                        </q-td>
                    </q-tr>
                </template>
            </q-table>

        </div>

        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>PROCEDIMIENTOS QUIRÚRGICOS PREVIOS</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-plus" @click="dialogProcedimientosQuirurgicosPrevios = true">
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Procedimientos Quirúrgicos Previos</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Procedimiento"
                @click="dialogProcedimientosQuirurgicosPrevios = true" />
            <div class="element-linea"></div>
        </div>

        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="paciente.prodedimientosQuirurgicos" new-value-mode="add" :columns="columnsProcedimientos"
                :rows-per-page-options="[0]" row-key="procedimiento" separator="cell"
                no-data-label="Sin datos disponibles">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarProcedimiento(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="procedimiento" :props="props">{{ props.row.procedimiento }}
                        </q-td>
                        <q-td key="fecha" :props="props">
                            {{ props.row.fecha }}
                        </q-td>

                    </q-tr>
                </template>
            </q-table>

        </div>
        <!-- <div class="col-12 q-mt-xl">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>TRAUMATISMOS PREVIOS</div>
                <q-space />
                <q-btn style="width: 40px; height: 40px; font-size: 14px;" class="text-blue" round color="white"
                    icon="mdi-plus" @click="dialogTraumatismosPrevios = true">
                    <q-tooltip>
                        Agregar
                    </q-tooltip>
                </q-btn>
            </q-bar>
        </div> -->

        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Traumatismos Previos</div>
            <q-space />
            <q-btn size="sm" class="btn-estilos" icon="mdi-plus" unelevated rounded
                style="background:#1A4161; color:white" label="Agregar Traumatismo"
                @click="dialogTraumatismosPrevios = true" />
            <div class="element-linea"></div>
        </div>

        <div class="col-12">
            <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md"
                :data="paciente.traumatismosPrevios" :columns="columnsTraumatismo" :rows-per-page-options="[0]"
                row-key="tipo" separator="cell" no-data-label="Sin datos disponibles">
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" style="color:#1A4161" rounded flat dense
                                @click="eliminarTraumatismo(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipo" :props="props">{{ props.row.tipo }}
                        </q-td>
                        <q-td auto-width key="fracturas">
                            <q-btn size="md" color="primary" rounded flat dense @click="verFracturas(props.row)"
                                icon="mdi-format-list-bulleted">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Ver Sitios de Lesión</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td auto-width key="articulaciones">
                            <q-btn size="md" color="primary" rounded flat dense @click="verArticulaciones(props.row)"
                                icon="mdi-format-list-bulleted">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Ver Articulaciones</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="fechaTraumatismo" :props="props">
                            {{ props.row.fechaTraumatismo }}
                        </q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsfiltroEnfermedades: null,
            itemsfiltroTratamiento: null,
            itemsfiltroProcedimiento: null,
            itemsfiltroTraumatismos: null,
            itemsfiltroFracturas: null,
            itemsfiltroArticulaciones: null,
            dialogDetalles: false,
            detalles: [],
            titulo: '',
            columnsDetalles: [
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
            ],
            enfermedad: {
                diagnostico: '',
                añosEvolucion: 0,
                tratamientos: []
            },
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'diagnostico', align: 'center', label: 'Enfermedad', field: 'diagnostico', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'añosEvolucion', align: 'center', label: 'Años de evolución', field: 'añosEvolucion', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'tratamientos', align: 'center', label: 'Tratamientos', field: 'tratamientos', sortable: true },
            ],
            procedimiento: {
                procedimiento: '',
                fecha: null,
            },
            columnsProcedimientos: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'procedimiento', align: 'center', label: 'Procedimiento', field: 'procedimiento', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'fecha', align: 'center', label: 'Fecha de procedimiento', field: 'fecha', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
            ],
            traumatismo: {
                tipo: '',
                fracturas: {
                    fracturas: [],
                    fecha: null,
                },
                articulaciones: {
                    articulaciones: [],
                    fecha: null,
                },
                fechaTraumatismo: null
            },
            columnsTraumatismo: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'tipo', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'fracturas', align: 'center', label: 'Sitios de Lesión', field: 'fracturas', sortable: true, },
                { name: 'articulaciones', align: 'center', label: 'Articulaciones', field: 'articulaciones', sortable: true, classes: 'text-left', },
                { name: 'fechaTraumatismo', align: 'center', label: 'Traumatismo craneoncefálico/medular', field: 'fechaTraumatismo', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
            ],
            dialogEnfermedadesCronicoDegenerativas: false,
            dialogProcedimientosQuirurgicosPrevios: false,
            dialogTraumatismosPrevios: false
        }
    },
    computed: {
        paciente() {
            return this.$store.state.pacienteStore.antecPersonalesPatologicos;
        },
        itmesProcedimientos() {
            return this.$store.state.listaCatalogoProcedimientos
        },
        itmesTraumatismos() {
            return this.$store.state.listaCatalogoTraumatismos
        },
        itmesHuesos() {
            return this.$store.state.listaCatalogoHuesos
        },
        itmesArticulaciones() {
            return this.$store.state.listaCatalogoArticulaciones
        },
        itemsEnfermedadesCronicas() {
            return this.$store.state.listaCatalogoEnfermedadesCronicas
        },
        itemsTratamientos() {
            return this.$store.state.listaCatalogoTratamientos
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.traumatismo.fechaTraumatismo ? moment.utc(this.traumatismo.fechaTraumatismo).format('DD/MMMM/yyyy') : ''
        },
        fehaProcedimiento() {
            moment.locale('es-mx');
            return this.procedimiento.fecha ? moment.utc(this.procedimiento.fecha).format('DD/MMMM/yyyy') : ''
        },
        fehaFractura() {
            moment.locale('es-mx');
            return this.traumatismo.fracturas.fecha ? moment.utc(this.traumatismo.fracturas.fecha).format('DD/MMMM/yyyy') : ''
        },
        fehaArticulaciones() {
            moment.locale('es-mx');
            return this.traumatismo.articulaciones.fecha ? moment.utc(this.traumatismo.articulaciones.fecha).format('DD/MMMM/yyyy') : ''
        },
    },
    watch: {
    },
    created() {
    },
    methods: {
        filtroEnfermedades(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroEnfermedades = this.itemsEnfermedadesCronicas.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroEnfermedades.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroTratamiento(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroTratamiento = this.itemsTratamientos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroTratamiento.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        createValueTratamiento(val, done) {
            if (val.length > 0) {
                if (!this.itemsTratamientos.includes(val)) {
                    this.itemsTratamientos.push(val)
                }
                done(val, 'toggle')
            }
        },

        filtroProcedimientos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroProcedimiento = this.itmesProcedimientos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroProcedimiento.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroTraumatismos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroTraumatismos = this.itmesTraumatismos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroTraumatismos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        createValueFracturas(val, done) {
            if (val.length > 0) {
                if (!this.itmesHuesos.includes(val)) {
                    this.itmesHuesos.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroFracturas(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroFracturas = this.itmesHuesos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroFracturas.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueArticulaciones(val, done) {
            if (val.length > 0) {
                if (!this.itmesArticulaciones.includes(val)) {
                    this.itmesArticulaciones.push(val)
                }
                done(val, 'toggle')
            }
        },
        filtroArticulaciones(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroArticulaciones = this.itmesArticulaciones.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroArticulaciones.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        agregarEnfermedad() {
            if (this.enfermedad.diagnostico == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una enfermedad' })
                return;
            }
            if (this.enfermedad.tratamientos.length == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique los tratamientos' })
                return;
            }
            if (this.enfermedad.añosEvolucion == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un año mayor a 0' })
                return;
            }
            if (this.enfermedad.añosEvolucion < 1 || this.enfermedad.añosEvolucion > 100) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'El número de años de evolución debe estar entre 1 y 100' })
                this.enfermedad.añosEvolucion = 0
                return;
            }

            this.$store.state.pacienteStore.antecPersonalesPatologicos.enfermedadesCronicos.push(this.enfermedad);

            this.enfermedad = {
                diagnostico: '',
                tratamientos: [],
                añosEvolucion: 0
            }
        },

        eliminarEnfermedad(data) {
            let editedIndex = this.$store.state.pacienteStore.antecPersonalesPatologicos.enfermedadesCronicos.indexOf(data)
            this.$store.state.pacienteStore.antecPersonalesPatologicos.enfermedadesCronicos.splice(editedIndex, 1)
        },

        agregarProcedimiento() {
            if (this.procedimiento.procedimiento == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique un procedimiento' })
                return;
            }
            if (this.procedimiento.fecha == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha del procedimiento' })
                return;
            }
            this.$store.state.pacienteStore.antecPersonalesPatologicos.prodedimientosQuirurgicos.push(this.procedimiento);

            this.procedimiento = {
                procedimiento: '',
                fecha: null
            }
        },

        eliminarProcedimiento(data) {
            let editedIndex = this.$store.state.pacienteStore.antecPersonalesPatologicos.prodedimientosQuirurgicos.indexOf(data)
            this.$store.state.pacienteStore.antecPersonalesPatologicos.prodedimientosQuirurgicos.splice(editedIndex, 1)
        },

        agregarTraumatismo() {


            if (this.traumatismo.tipo == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de traumatismo' })
                return;
            }
            if (this.traumatismo.fracturas.fracturas.length != 0) {
                if (this.traumatismo.fracturas.fecha == null) {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha de la lesión' })
                    return;
                }
            }

            if (this.traumatismo.articulaciones.articulaciones.length != 0) {
                if (this.traumatismo.articulaciones.fecha == null) {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha del traumatimso en articulaciones' })
                    return;
                }
            }
            this.$store.state.pacienteStore.antecPersonalesPatologicos.traumatismosPrevios.push(this.traumatismo);

            this.traumatismo = {
                tipo: '',
                fracturas: {
                    fracturas: [],
                    fecha: null,
                },
                articulaciones: {
                    articulaciones: [],
                    fecha: null,
                },
                fechaTraumatismo: null
            }
        },
        eliminarTraumatismo(data) {
            let editedIndex = this.$store.state.pacienteStore.antecPersonalesPatologicos.traumatismosPrevios.indexOf(data)
            this.$store.state.pacienteStore.antecPersonalesPatologicos.traumatismosPrevios.splice(editedIndex, 1)
        },
        verArticulaciones(data) {
            console.log(data)
            console.log(data)
            let lista = []
            for (let a of data.articulaciones.articulaciones) {
                let objeto = {
                    descripcion: a
                }
                lista.push(objeto)
            }
            this.detalles = lista
            this.dialogDetalles = true
            this.titulo = 'Articulaciones'
        },
        verFracturas(data) {
            console.log(data)

            let lista = []
            for (let a of data.fracturas.fracturas) {
                let objeto = {
                    descripcion: a
                }
                lista.push(objeto)
            }
            this.detalles = lista
            this.dialogDetalles = true
            this.titulo = 'Sitios de Lesión'
        },
        verTratamientos(data) {
            console.log(data)
            let lista = []
            for (let a of data.tratamientos) {
                let objeto = {
                    descripcion: a
                }
                lista.push(objeto)
            }
            this.detalles = lista
            this.dialogDetalles = true
            this.titulo = 'Trtamientos'
        }
    }
}
</script>