import jsPDF from 'jspdf';
import moment from 'moment'

function FormatoFecha(value) {
    moment.locale('es');
    return moment(value).format('DD-MMMM-YYYY')
}

function DrawGradientLine(doc, startX, startY, endX, endY, startColor, endColor, lineWidth) {
    // Longitud de la línea
    var length = endX - startX;
    doc.setLineWidth(lineWidth);
    // Dibujar la línea con degradado
    for (var i = 0; i <= length; i++) {
        var ratio = i / length;

        // Interpolación de colores
        var r = Math.floor(startColor[0] + ratio * (endColor[0] - startColor[0]));
        var g = Math.floor(startColor[1] + ratio * (endColor[1] - startColor[1]));
        var b = Math.floor(startColor[2] + ratio * (endColor[2] - startColor[2]));

        // Establecer el color de la línea
        doc.setDrawColor(r, g, b);

        // Dibujar un segmento de la línea
        doc.line(startX + i, startY, startX + i + 1, endY);
    }
}

function hexToRgbArray(hex) {
    // Elimina el símbolo # si está presente
    hex = hex.replace(/^#/, '');

    // Divide el valor hexadecimal en componentes R, G y B
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return [r, g, b];
}

const PdfInformeMedico = async (ObjInforme, nombreArchivo, tipoHoja, ObjConfig, ObjPerfil) => {
    return new Promise((resolve, reject) => {
        try {
            // const doc = new jsPDF();
            let tamañoHoja = [
                215.9, 279.4
            ]
            if (tipoHoja == 0) {
                tamañoHoja = [
                    215.9, 148.5
                ]
            }
            const doc = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: tamañoHoja
            });

            let ejeX = 10;
            let ejeY = 10;

            //APARTADO PARA LOS COLORES
            var startColor = hexToRgbArray(ObjConfig.colorDocumentos);
            var endColor = [0, 0, 0];

            //APARTADO PARA EL ENCABEZADO
            if (ObjConfig.imagenDocumento === "Logo") {
                if (ObjConfig.urlLogo) {
                    doc.addImage(ObjConfig.urlLogo, 'PNG', ejeX, ejeY, 34, 25);
                    doc.setFontSize(14);
                    doc.setFont('helvetica', 'bold');
                    doc.text(ObjPerfil.nombreMedico, ejeX + 200, ejeY + 4, { align: 'right' });
                    doc.setFontSize(9);
                    doc.setFont('helvetica', 'normal');
                    doc.text(ObjPerfil.institucion, ejeX + 200, ejeY + 7, { align: 'right' });
                    doc.text(ObjPerfil.especialidad, ejeX + 200, ejeY + 10, { align: 'right' });
                    doc.text(ObjPerfil.cedulaProfesional, ejeX + 200, ejeY + 13, { align: 'right' });

                    ejeY += 34;
                }
            } else if (ObjConfig.imagenDocumento === "Banner") {
                if (ObjConfig.urlBanner) {
                    doc.addImage(ObjConfig.urlBanner, 'PNG', ejeX - 10, ejeY - 10, 216, 28);
                    ejeY += 24;
                }
            } else if (ObjConfig.imagenDocumento === "") {
                const rutaImagen = require('../assets/cancelado.png');
                doc.addImage(rutaImagen, 'PNG', ejeX, ejeY, 34, 25);
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text(ObjPerfil.nombreMedico, ejeX + 200, ejeY + 4, { align: 'right' });
                doc.setFontSize(9);
                doc.setFont('helvetica', 'normal');
                doc.text(ObjPerfil.institucion, ejeX + 200, ejeY + 7, { align: 'right' });
                doc.text(ObjPerfil.especialidad, ejeX + 200, ejeY + 10, { align: 'right' });
                doc.text(ObjPerfil.cedulaProfesional, ejeX + 200, ejeY + 13, { align: 'right' });
                ejeY += 24;
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
            }

            //INICIAMOS EL REPORTE
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('INFORME MÉDICO', ejeX, ejeY, { align: 'left' });

            //DATOS DEL PACIENTE
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.text('INFORMACIÓN DEL PACIENTE', ejeX, ejeY += 8, { align: 'left' });
            // doc.text('Información del paciente', ejeX, ejeY += 10, { align: 'left' });
            DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
            doc.setFontSize(7);
            doc.setFont('helvetica', 'bold');
            doc.text('Apellidos', ejeX, ejeY += 4, { align: 'left' });
            doc.text('Nombre', ejeX + 90, ejeY, { align: 'left' });
            doc.text('Fecha de nacimiento', ejeX + 160, ejeY, { align: 'left' });
            doc.setFontSize(10);
            doc.setFont('helvetica', 'normal');
            doc.text(ObjInforme.apellidos, ejeX, ejeY += 3, { align: 'left' });
            doc.text(ObjInforme.nombre, ejeX + 90, ejeY, { align: 'left' });
            doc.text(ObjInforme.fechaNacimiento, ejeX + 160, ejeY, { align: 'left' });
            doc.setFontSize(7);
            doc.setFont('helvetica', 'bold');
            doc.text('Género', ejeX, ejeY += 4, { align: 'left' });
            doc.text('Edad', ejeX + 90, ejeY, { align: 'left' });
            doc.text('Fecha de emisión del informe', ejeX + 160, ejeY, { align: 'left' });
            doc.setFontSize(10);
            doc.setFont('helvetica', 'normal');
            doc.text(ObjInforme.genero, ejeX, ejeY += 3, { align: 'left' });
            doc.text(ObjInforme.edad, ejeX + 90, ejeY, { align: 'left' });
            doc.text(ObjInforme.fechaEmision, ejeX + 160, ejeY, { align: 'left' });

            //INFORMACIÓN DEL DIAGNÓSTICO
            const infoDiagnostico = ObjInforme.infoDiagnostico;
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            // doc.text('Información del diagnóstico', ejeX, ejeY += 6, { align: 'left' });
            doc.text('INFORMACIÓN DEL DIAGNÓSTICO', ejeX, ejeY += 6, { align: 'left' });
            DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
            doc.setFontSize(7);
            doc.setFont('helvetica', 'bold');
            doc.text('Fecha de inicio', ejeX, ejeY += 4, { align: 'left' });
            doc.setFontSize(10);
            doc.setFont('helvetica', 'normal');
            doc.text(infoDiagnostico.fechaDiagnostico, ejeX, ejeY += 3, { align: 'left' });
            doc.setFontSize(7);
            doc.setFont('helvetica', 'bold');
            doc.text('Nombre de la enfermedad', ejeX, ejeY += 4, { align: 'left' });
            let diagnostico = "";
            let cont_d = 1;
            let acum_d = infoDiagnostico.diagnosticos.length;
            for (let e of infoDiagnostico.diagnosticos) {
                diagnostico += e
                if (cont_d != acum_d) {
                    diagnostico += " | "
                }
                cont_d++;
            }
            doc.setFontSize(10);
            doc.setFont('helvetica', 'normal');
            doc.text(diagnostico, ejeX, ejeY += 3, { align: 'left' });
            doc.setFontSize(7);
            doc.setFont('helvetica', 'bold');
            doc.text('Características del diagnóstico', ejeX, ejeY += 4, { align: 'left' });
            doc.setFontSize(10);
            doc.setFont('helvetica', 'normal');
            doc.text(infoDiagnostico.caracteristicas, ejeX, ejeY += 3, { align: 'left' });
            doc.setFontSize(7);
            doc.setFont('helvetica', 'bold');
            doc.text('Estadio al diagnóstico', ejeX, ejeY += 4, { align: 'left' });
            doc.setFontSize(10);
            doc.setFont('helvetica', 'normal');
            doc.text(infoDiagnostico.estadioDiagnostico, ejeX, ejeY += 3, { align: 'left' });

            //ANTECEDENTES HEREDOFAMILIARES            
            const antecedentesH = ObjInforme.antecHeredofammiliares;
            if (antecedentesH.length != 0) {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Antecedentes heredofamiliares', ejeX + 95, ejeY += 6, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
                ejeY += 1;
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.text('Enfermedad', ejeX, ejeY += 4, { align: 'left' });
                doc.text('Familiar', ejeX + 130, ejeY, { align: 'left' });
                doc.text('Edad', ejeX + 190, ejeY, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, .5);
                ejeY += 2;
                doc.setFont('helvetica', 'normal');
                for (var ah of antecedentesH) {
                    doc.text(ah.diagnostico, ejeX, ejeY += 3, { align: 'left' });
                    doc.text(ah.familiar, ejeX + 130, ejeY, { align: 'left' });
                    doc.text(ah.edad, ejeX + 190, ejeY, { align: 'center' });
                    ejeY += 2;
                }
            }

            //ANTECEDENTES PERSONALES NO PATOLOGICOS
            const antecedentesPNP = ObjInforme.antecPersonalesNoPatologicos;
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.text('ANTECEDENTES PERSONALES NO PATOLÓGICOS', ejeX, ejeY += 6, { align: 'left' });
            // doc.text('Antecedentes personales no patológicos', ejeX, ejeY += 6, { align: 'left' });
            DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
            //HEMOTIPO
            doc.setFontSize(7);
            doc.setFont('helvetica', 'bold');
            doc.text('Hemotipo', ejeX, ejeY += 4, { align: 'left' });
            doc.setFontSize(10);
            doc.setFont('helvetica', 'normal');
            doc.text(antecedentesPNP.hemotipo, ejeX, ejeY += 3, { align: 'left' });

            //ALERGIAS
            if (antecedentesPNP.alergias.length != 0) {
                doc.setFontSize(7);
                doc.setFont('helvetica', 'bold');
                doc.text('Alergias', ejeX, ejeY += 4, { align: 'left' });
                let alergias = "";
                let cont = 1;
                let acum = antecedentesPNP.alergias.length;
                for (let e of antecedentesPNP.alergias) {
                    alergias += e
                    if (cont != acum) {
                        alergias += " | "
                    }
                    cont++;
                }
                doc.setFontSize(10);
                doc.setFont('helvetica', 'normal');
                doc.text(alergias, ejeX, ejeY += 3, { align: 'left' });
            }

            //EXPOSICION BIOMASA
            if (antecedentesPNP.exposicionBiomasa.años != 0 || antecedentesPNP.exposicionBiomasa.horas != 0) {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Exposición biomasa', ejeX, ejeY += 6, { align: 'left' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
                doc.setFontSize(7);
                doc.setFont('helvetica', 'bold');
                doc.text('Horas al día', ejeX, ejeY += 4, { align: 'left' });
                doc.text('Años con exposición', ejeX + 24, ejeY, { align: 'left' });
                doc.setFontSize(10);
                doc.setFont('helvetica', 'normal');
                doc.text(antecedentesPNP.exposicionBiomasa.años, ejeX, ejeY += 3, { align: 'left' });
                doc.text(antecedentesPNP.exposicionBiomasa.horas, ejeX + 24, ejeY, { align: 'left' });
            }

            //TABAQUISMO
            if (antecedentesPNP.tabaquismo.numCigarros != 0 || antecedentesPNP.tabaquismo.años != 0) {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Tabaquismo', ejeX, ejeY += 6, { align: 'left' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
                doc.setFontSize(7);
                doc.setFont('helvetica', 'bold');
                doc.text('Cigarrillos al día', ejeX, ejeY += 4, { align: 'left' });
                doc.text('Años fumando', ejeX + 24, ejeY, { align: 'left' });
                doc.setFontSize(10);
                doc.setFont('helvetica', 'normal');
                doc.text(antecedentesPNP.tabaquismo.numCigarros, ejeX, ejeY += 3, { align: 'left' });
                doc.text(antecedentesPNP.tabaquismo.años, ejeX + 24, ejeY, { align: 'left' });
            }

            //ALOHOLISMO
            if (antecedentesPNP.alcoholismo.length != 0) {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Alcoholismo', ejeX + 95, ejeY += 6, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.text('Bebida alcoholica', ejeX, ejeY += 5, { align: 'left' });
                doc.text('Frecuencia', ejeX + 110, ejeY, { align: 'left' });
                doc.text('Número de copas', ejeX + 170, ejeY, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, .5);
                ejeY += 2;
                doc.setFont('helvetica', 'normal');
                for (var alco of antecedentesPNP.alcoholismo) {
                    doc.text(alco.bebida, ejeX, ejeY += 3, { align: 'left' });
                    doc.text(alco.frecuencia, ejeX + 110, ejeY, { align: 'left' });
                    doc.text(alco.numCopas, ejeX + 170, ejeY, { align: 'center' });
                    ejeY += 2;
                }
            }

            //OTRAS TOXICOMANÍAS
            if (antecedentesPNP.toxicomanias.length != 0) {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Otras toxicomanías', ejeX + 95, ejeY += 6, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.text('Toxicomanía', ejeX, ejeY += 5, { align: 'left' });
                doc.text('Frecuencia', ejeX + 170, ejeY, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, .5);
                ejeY += 2;
                doc.setFont('helvetica', 'normal');
                for (var toxi of antecedentesPNP.toxicomanias) {
                    doc.text(toxi.toxicomania, ejeX, ejeY += 3, { align: 'left' });
                    doc.text(toxi.frecuencia, ejeX + 170, ejeY, { align: 'center' });
                    ejeY += 4;
                }
            }

            //ANTECEDENTES PERSONALES PATOLÓGICOS
            const antecedentesPP = ObjInforme.antecPersonalesPatologicos;
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.text('ANTECEDENTES PERSONALES PATOLÓGICOS', ejeX, ejeY += 6, { align: 'left' });
            // doc.text('Antecedentes personales patológicos', ejeX, ejeY += 6, { align: 'left' });
            DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);

            //ENFERMEDADES CRÓNICO DEGENERATIVAS
            if (antecedentesPP.enfermedadesCronicos.length != 0) {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Enfermedades crónico degenerativas', ejeX + 95, ejeY += 6, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.text('Enfermedad', ejeX, ejeY += 5, { align: 'left' });
                doc.text('Años de evolución', ejeX + 100, ejeY, { align: 'center' });
                doc.text('Tratamiento', ejeX + 120, ejeY, { align: 'left' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, .5);
                ejeY += 2;
                doc.setFont('helvetica', 'normal');
                for (var cronico of antecedentesPP.enfermedadesCronicos) {
                    doc.text(cronico.diagnostico, ejeX, ejeY += 3, { align: 'left' });
                    doc.text(cronico.añosEvolucion, ejeX + 100, ejeY, { align: 'center' });
                    let tratamientos = ""
                    let cont = 1;
                    let acum = cronico.tratamientos.length;
                    for (var t of cronico.tratamientos) {
                        tratamientos += t;
                        if (cont != acum) {
                            tratamientos += " | "
                        }
                        cont++;
                    }
                    doc.text(tratamientos, ejeX + 120, ejeY, { align: 'left' });
                    ejeY += 2;
                }
            }

            //PROCEDIMIENTO QUIRURJICOS PREVIOS
            if (antecedentesPP.prodedimientosQuirurgicos.length != 0) {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Procedimientos quirúrgicos previos', ejeX + 95, ejeY += 6, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.text('Procedimiento', ejeX, ejeY += 5, { align: 'left' });
                doc.text('Fecha de procedimiento', ejeX + 160, ejeY, { align: 'left' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, .5);
                ejeY += 2;
                doc.setFont('helvetica', 'normal');
                for (var quir of antecedentesPP.prodedimientosQuirurgicos) {
                    doc.text(quir.procedimiento, ejeX, ejeY += 3, { align: 'left' });
                    doc.text(quir.fecha, ejeX + 160, ejeY, { align: 'left' });
                    ejeY += 4;
                }
            }

            //TRAUMATISMOS PREVIOS
            if (antecedentesPP.traumatismosPrevios.length != 0) {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Traumatismos previos', ejeX + 95, ejeY += 6, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.text('Tipo de traumatismo', ejeX, ejeY += 5, { align: 'left' });
                doc.text('Fecha traumatismo craneoencefálico/medular', ejeX + 160, ejeY, { align: 'left' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, .5);
                ejeY += 2;
                doc.setFont('helvetica', 'normal');
                for (var trau of antecedentesPP.traumatismosPrevios) {
                    doc.text(trau.tipo, ejeX, ejeY += 3, { align: 'left' });
                    doc.text(trau.fracturas, ejeX + 160, ejeY, { align: 'left' }); //son listas hay que modificar
                    doc.text(trau.articulaciones, ejeX + 160, ejeY, { align: 'left' }); //son listas, hay que modificar
                    doc.text(trau.fechaTraumatismo, ejeX + 160, ejeY, { align: 'left' });
                    ejeY += 4;
                }
            }

            //ANTECEDENTES ONCOLÓGICOS
            const antecedentesOnc = ObjInforme.antecOncologicos;
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.text('ANTECEDENTES ONCOLÓGICOS', ejeX, ejeY += 6, { align: 'left' });
            DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);

            //TERAPIA SISTÉMICA
            if (antecedentesOnc.radioterapias.length != 0) {
                doc.setFontSize(7);
                doc.setFont('helvetica', 'bold');
                doc.text('Terapia sistémica', ejeX, ejeY += 4, { align: 'left' });
                let texto = "";
                let cont = 1;
                let acum = antecedentesOnc.terapias.length;
                for (let e of antecedentesOnc.terapias) {
                    texto += e
                    if (cont != acum) {
                        texto += ", "
                    }
                    cont++;
                }
                doc.setFontSize(10);
                doc.setFont('helvetica', 'normal');
                doc.text(texto, ejeX, ejeY += 3, { align: 'left' });
            }

            //RADIOTERAPIA
            if (antecedentesOnc.radioterapias.length != 0) {
                doc.setFontSize(7);
                doc.setFont('helvetica', 'bold');
                doc.text('Radioterapia', ejeX, ejeY += 4, { align: 'left' });
                let texto = "";
                let cont = 1;
                let acum = antecedentesOnc.radioterapias.length;
                for (let e of antecedentesOnc.radioterapias) {
                    texto += e
                    if (cont != acum) {
                        texto += ", "
                    }
                    cont++;
                }
                doc.setFontSize(10);
                doc.setFont('helvetica', 'normal');
                doc.text(texto, ejeX, ejeY += 3, { align: 'left' });
            }

            //PROCEDIMIENTO QUIRURJICO
            if (antecedentesOnc.procedimientos.length != 0) {
                doc.setFontSize(7);
                doc.setFont('helvetica', 'bold');
                doc.text('Procedimienot qirúrgico', ejeX, ejeY += 4, { align: 'left' });
                let texto = "";
                let cont = 1;
                let acum = antecedentesOnc.procedimientos.length;
                for (let e of antecedentesOnc.procedimientos) {
                    texto += e
                    if (cont != acum) {
                        texto += ", "
                    }
                    cont++;
                }
                doc.setFontSize(10);
                doc.setFont('helvetica', 'normal');
                doc.text(texto, ejeX, ejeY += 3, { align: 'left' });
            }

            //HISTORIAL DEL PADECIMIENTO
            const hitsorialP = ObjInforme.historialPadecimiento;
            if (hitsorialP.sintomas.length != 0) {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Historial del padecimiento', ejeX + 95, ejeY += 6, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, 1);
                doc.setFontSize(10);
                doc.setFont('helvetica', 'bold');
                doc.text('Síntoma', ejeX, ejeY += 5, { align: 'left' });
                doc.text('Intensidad del síntoma', ejeX + 180, ejeY, { align: 'center' });
                DrawGradientLine(doc, 10, ejeY + 1, 210, ejeY + 1, startColor, endColor, .5);
                ejeY += 2;
                doc.setFont('helvetica', 'normal');
                for (var hist of hitsorialP.sintomas) {
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(10);
                    doc.text(hist.sintoma, ejeX, ejeY += 3, { align: 'left' });
                    doc.text(hist.intensidad, ejeX + 180, ejeY, { align: 'center' });
                    if (hist.partesCuerpo.length != 0) {
                        let partes = "";
                        let cont = 1;
                        let acum = hist.partesCuerpo.length;
                        for (var p of hist.partesCuerpo) {
                            partes += p;
                            if (cont != acum) {
                                partes += " | "
                            }
                            cont++;
                        }
                        // doc.text(hist.partesCuerpo, ejeX + 110, ejeY, { align: 'left' }); //son listas hay que modificar
                        ejeY += 2;
                        doc.setFontSize(7);
                        doc.setFont('helvetica', 'italic');
                        doc.text(partes, ejeX, ejeY, { align: 'left' }); //son listas hay que modificar
                    } else {
                        ejeY += 3;
                    }
                    ejeY += 1;
                }
            }

            //COMENTARIOS
            const comentarios = ObjInforme.comentarios;
            if (comentarios != "") {
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Comentarios adicionales', ejeX, ejeY += 6, { align: 'left' });
                doc.setFontSize(10);
                doc.setFont('helvetica', 'normal');
                doc.text(comentarios, ejeX, ejeY += 3, { align: 'left' });
            }

            //AGREGAMOS EL PIE DE PAGINA
            var totalPages = doc.internal.getNumberOfPages();
            for (var i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                doc.setFont("helvetica", "italic");
                doc.setFontSize(10);
                doc.setTextColor(150);
                doc.text('Documento generado por ContaGo', 10, doc.internal.pageSize.getHeight() - 10, { align: 'left' });
                doc.text('Página ' + i + ' de ' + totalPages, 200, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
            }
            //EXPORTACION DE LA RECETA
            const base64 = doc.output('datauristring');
            let nombreDoc = nombreArchivo;
            // doc.save(nombreDoc + '.pdf');
            resolve(base64);
        } catch (error) {
            console.log(error);
        }
    });
};

export { PdfInformeMedico };