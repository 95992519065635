<template>
  <q-layout>
    <q-page-container class="flex flex-center">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
//import Inicio from "./views/Home.vue"
import Inicio from "./components/Main/MainPage.vue"
import axios from 'axios'
import { colors } from 'quasar'
export default {
  name: 'LayoutDefault',

  components: {
    Inicio
  },

  data() {
    return {
      leftDrawerOpen: false
    }
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
  },
  created() {
    // CATALOGOS PACIENTES
    this.$store.dispatch("autoLogin");
    this.$q.dark.set(this.$store.state.isDark);
    this.getCatalogoAseguradoras();
    this.getCatalogoPais();
    this.getCatalogoDiagnosticosCIE();
    this.getCatalogoFamiliares();
    this.getCatalogoEstados();
    this.getCatalogoMunicipios();
    this.getCatalogoEstadosCiviles();
    this.getCatalogoOcupaciones();
    this.getCatalogoReligiones();
    this.getCatalogoEscolsridad();
    this.GetCatalogoToxicomanias();
    this.GetCatalogoBebidasAlcoholicas();
    this.GetCatalogoHemotipos();
    this.GetCatalogoAlergias();
    this.GetCatalogoIntoleranciaAlimentos();
    this.GetCatalogoActividadesFisicas();
    this.GetCatalogoZoonosis();
    this.GetCatalogoAnimales();
    this.GetCatalogoEnfermedadesCronicas();
    this.GetCatalogoArticulaciones();
    this.GetCatalogoHuesos();
    this.GetCatalogoProcedimientosQuirurgicos();
    this.GetCatalogoTraumatismos();
    this.GetCatalogoTratamientos();
    this.GetCatalogoETS();
    this.GetCatalogoMPF();
    this.GetCatalogoRadioterapias();
    this.GetCatalogoTerapiasSistemicas();
    this.GetCatalogoSintomas();
    this.GetCatalogoPartesCuerpo();

    // CATALOGOS CONSULTAS
    this.GetCatalogoSintomasAbdomenPelvis();
    this.GetCatalogoSintomasCabezaCuello();
    this.GetCatalogoSintomasExtremidades();
    this.GetCatalogoSintomasNeurologicos();
    this.GetCatalogoSintomasTegumemntos();
    this.GetCatalogoSintomasToraxEspalda();
    this.GetCatalogoPartesCuerpo();
    this.GetCatalogoEstudiosImagenologicos();
    this.GetCatalogoEstudiosLaboratorio();
    this.GetCatalogoEstudiosMoleculares();
    this.GetCatalogoMedicamamentosObjeto();
    this.GetCatalogoDiagnosticoString();
    this.GetCatalogoServiciosMedicos();
    this.GetCatalogoEstadioDiagnostico();
    this.GetCatalogoMotivosConsulta();
    this.GetCatalogoViasAdministracion();
    this.GetCatalogoDuracionTratamiento();
    this.GetCatalogoFrecuenciaAdministracion();

  },
  methods: {
    // GET CATALOGOS PACIENTES
    async getCatalogoAseguradoras() {
      if (this.$store.state.listaCatalogoAseguradoras.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoAseguradoras/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoAseguradoras = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async getCatalogoPais() {
      if (this.$store.state.listaCatalogoPaises.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoPais/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoPaises = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async getCatalogoDiagnosticosCIE() {
      if (this.$store.state.listaCatalogoDiagnosticosCIE.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoDiagnosticoString/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoDiagnosticosCIE = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async getCatalogoFamiliares() {
      if (this.$store.state.listaCatalogoFamiliares.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoFamiliares/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoFamiliares = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async getCatalogoEstados() {
      if (this.$store.state.listaCatalogoEstados.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstados/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstados = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async getCatalogoMunicipios() {
      if (this.$store.state.listaCatalogoMunicipios.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoMunicipios/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoMunicipios = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async getCatalogoReligiones() {
      if (this.$store.state.listaCatalogoReligiones.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoReligiones/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoReligiones = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async getCatalogoOcupaciones() {
      if (this.$store.state.listaCatalogoOcupaciones.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoOcupaciones/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoOcupaciones = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async getCatalogoEstadosCiviles() {
      if (this.$store.state.listaCatalogoEstadosCiviles.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstadosCiviles/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstadosCiviles = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async getCatalogoEscolsridad() {
      if (this.$store.state.listaCatalogoEscolaridad.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEscolaridad/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEscolaridad = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoAnimales() {
      if (this.$store.state.listaCatalogoAnimales.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoAnimales/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoAnimales = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoZoonosis() {
      if (this.$store.state.listaCatalogoZoonosis.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoZoonosis/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoZoonosis = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoActividadesFisicas() {
      if (this.$store.state.listaCatalogoActividadesFisicas.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoActividadesFisicas/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoActividadesFisicas = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoIntoleranciaAlimentos() {
      if (this.$store.state.listaCatalogoIntolerancias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoIntoleranciaAlimentos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoIntolerancias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoAlergias() {
      if (this.$store.state.listaCatalogoAlergias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoAlergias/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoAlergias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoHemotipos() {
      if (this.$store.state.listaCatalogoHemotipos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoHemotipos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoHemotipos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoBebidasAlcoholicas() {
      if (this.$store.state.listaCatalogoBebidas.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoBebidasAlcoholicas/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoBebidas = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoToxicomanias() {
      if (this.$store.state.listaCatalogoToxicomanias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoToxicomanias/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoToxicomanias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoEnfermedadesCronicas() {
      if (this.$store.state.listaCatalogoEnfermedadesCronicas.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEnfermedadesCronicas/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEnfermedadesCronicas = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoTratamientos() {
      if (this.$store.state.listaCatalogoTratamientos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoTratamientos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTratamientos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoProcedimientosQuirurgicos() {
      if (this.$store.state.listaCatalogoProcedimientos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoProcedimientosQuirurgicos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoProcedimientos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoTraumatismos() {
      if (this.$store.state.listaCatalogoTraumatismos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoTraumatismos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTraumatismos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoHuesos() {
      if (this.$store.state.listaCatalogoHuesos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoHuesos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoHuesos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoArticulaciones() {
      if (this.$store.state.listaCatalogoArticulaciones.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoArticulaciones/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoArticulaciones = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoETS() {
      if (this.$store.state.listaCatalogoETS.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoETS/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoETS = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoMPF() {
      if (this.$store.state.listaCatalogoMPF.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoMPF/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoMPF = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoRadioterapias() {
      if (this.$store.state.listaCatalogoRadioterapias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoRadioterapias/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoRadioterapias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoTerapiasSistemicas() {
      if (this.$store.state.listaCatalogoTerapias.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoTerapiasSistemicas/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTerapias = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoSintomas() {
      if (this.$store.state.listaCatalogoSintomas.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomas/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoSintomas = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
    async GetCatalogoPartesCuerpo() {
      if (this.$store.state.listaCatalogoPartesCuerpo.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoPartesCuerpo/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoPartesCuerpo = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    // GET CONSULTAS 
    async GetCatalogoSintomasNeurologicos() {
      if (this.$store.state.listaCatalogoNeurologicos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasNeurologicos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoNeurologicos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasCabezaCuello() {
      if (this.$store.state.listaCatalogoCabezaCuello.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasCabezaCuello/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoCabezaCuello = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasToraxEspalda() {
      if (this.$store.state.listaCatalogoToraxEspalda.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasToraxEspalda/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoToraxEspalda = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasAbdomenPelvis() {
      if (this.$store.state.listaCatalogoAbdomenPelvis.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasAbdomenPelvis/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoAbdomenPelvis = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasExtremidades() {
      if (this.$store.state.listaCatalogoExtremidades.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasExtremidades/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoExtremidades = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoSintomasTegumemntos() {
      if (this.$store.state.listaCatalogoTegumentos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoSintomasTegumemntos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoTegumentos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoPartesCuerpo() {
      if (this.$store.state.listaCatalogoPartesCuerpo.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoPartesCuerpo/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoPartesCuerpo = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEstudiosImagenologicos() {
      if (this.$store.state.listaCatalogoEstudiosImagenologicos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstudiosImagenologicos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstudiosImagenologicos = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEstudiosLaboratorio() {
      if (this.$store.state.listaCatalogoEstudiosLaboratorio.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstudiosLaboratorio/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstudiosLaboratorio = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEstudiosMoleculares() {
      if (this.$store.state.listaCatalogoEstudiosMoleculares.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstudiosMoleculares/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstudiosMoleculares = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoMedicamamentosObjeto() {
      if (this.$store.state.listaCatalogoMedicamentosObj.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoMedicamamentosObjeto/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoMedicamentosObj = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoDiagnosticoString() {
      if (this.$store.state.listaCatalogoDiagnosticosString.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoDiagnosticoString/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoDiagnosticosString = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoServiciosMedicos() {
      if (this.$store.state.listaCatalogoServciosMedicos.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoServiciosMedicos/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoServciosMedicos = catalogo;
      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoMotivosConsulta() {
      if (this.$store.state.listaCatalogoMotivosConsulta.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoMotivosConsulta/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoMotivosConsulta = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoEstadioDiagnostico() {
      if (this.$store.state.listaCatalogoEstadioDiagnostico.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoEstadioDiagnostico/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoEstadioDiagnostico = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoFrecuenciaAdministracion() {
      if (this.$store.state.listaCatalogoFrecuenciaAdministracion.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoFrecuenciaAdministracion/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoFrecuenciaAdministracion = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoDuracionTratamiento() {
      if (this.$store.state.listaCatalogoDuracionTratamiento.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoDuracionTratamiento/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoDuracionTratamiento = catalogo;

      } catch (error) {
        console.log(error);
      }
    },

    async GetCatalogoViasAdministracion() {
      if (this.$store.state.listaCatalogoViasAdministracion.length != 0) {
        return
      }
      try {
        let response = await axios.get("Catalogos/GetCatalogoViasAdministracion/dev");
        let catalogo = response.data;
        this.$store.state.listaCatalogoViasAdministracion = catalogo;

      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>
