<template>
    <div class="q-pa-md">
        <!-- PARA AGREGAR UN NUEVO CONCEPTO DE PAGO -->
        <q-dialog v-model="dialogConcepto" transition-show="scale" transition-hide="scale">
            <q-card style="min-width: 800px">
                <q-card-section>
                    <div class="row">
                        <div class="col text-left">
                            <q-btn round style="background:#1A4161; color:white" icon="mdi-close"
                                @click="dialogConcepto = false" />
                            <span class="text-h6">
                                Concepto de cobro
                            </span>
                        </div>
                    </div>
                    <div class="element-linea"></div>
                </q-card-section>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-6">
                            <q-input filled v-model="cobro.fecha" label="Fecha de consulta" dense readonly />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input filled v-model="cobro.paciente" label="Paciente" dense readonly />
                        </div>
                        <div class="col-12">
                            <q-input filled v-model="cobro.motivo_consulta" label="Concepto" dense />
                        </div>
                        <div class="col-12">
                            <q-input filled v-model="cobro.costo" label="Costo" dense type="number" />
                        </div>
                    </div>
                </q-card-section>
                <q-separator />
                <q-card-actions vertical>
                    <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                        style="background:#1A4161; color:white" @click="pushConcepto()">
                        Agregar concepto
                    </q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-card class="full-width">
            <q-card-section>
                <q-toolbar>
                    <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close" dense
                        @click="CloseDialogcobro()">
                        <q-tooltip>
                            Cerrar
                        </q-tooltip>
                    </q-btn>
                    <q-toolbar-title>
                        Cobro
                    </q-toolbar-title>
                    <!-- <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-content-save"
                        @click="PostCobro()">
                        <q-tooltip>
                            Guardar
                        </q-tooltip>
                    </q-btn> -->
                </q-toolbar>
                <div class="element-linea"></div>
            </q-card-section>
            <q-card-section>
                <q-table title="Cocenptos de cobro" :data="listConceptos" :columns="columnsConceptos" row-key="nombre"
                    :pagination="pagination" dense>
                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td auto-width key="acciones">
                                <q-btn size="md" style="color:#686868" rounded flat dense
                                    @click="deleteConcepto(props.row)" icon="mdi-delete">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Eliminar</q-tooltip>
                                </q-btn>
                            </q-td>
                            <q-td key="motivo_consulta" :props="props">{{ props.row.motivo_consulta }}</q-td>
                            <q-td key="costo" :props="props">{{ props.row.costo }}</q-td>
                        </q-tr>
                    </template>
                </q-table>
            </q-card-section>
        </q-card>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import { date } from 'quasar'
import moment from 'moment';

export default {
    components: {

    },
    data() {
        return {
            dialogConcepto: true,
            listConceptos: [],
            columnsConceptos: [
                { name: "actions", align: "center", label: "Acciones", field: "actions", },
                { name: "motivo_consulta", align: "center", label: "Concepto", field: "motivo_consulta", sortable: true, },
                { name: "costo", align: "center", label: "Costo", field: "costo", sortable: true, },
            ],
            pagination: {
                descending: false,
                page: 1,
                rowsPerPage: 10,
            },

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        cobro() {
            return this.$store.state.cobrosStore;
        },

    },

    watch: {
    },

    created() {

    },

    methods: {
        async PostCobro() { },

        CloseDialogcobro() {
            this.$emit("cierraCobro");
        },

        pushConcepto() {
            let c = { ...this.cobro }
            let cDefault = {
                item: 0,
                costo: 0,
                fecha: c.fecha,
                id_consulta: c.id_consulta,
                id_consultorio: c.id_consultorio,
                id_medico: c.id_medico,
                id_paciente: c.id_paciente,
                medico: c.medico,
                motivo_consulta: "",
                paciente: c.paciente,
                telefono: c.telefono,
            }
            if (c.costo == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: 'El costo debe ser diferente a 0.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }
            this.listConceptos.push(c)
            this.$store.state.cobrosStore = { ...cDefault }
            var cont = 1;
            for (let x of this.listConceptos) {
                x.item = cont;
                cont++;
            }
        },

        deleteConcepto() {

        },
    }
}
</script>