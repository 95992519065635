<template>
    <div class="row q-col-gutter-sm">
        <div class="col-12">
            <q-bar style="background:#1A4161; color:white">
                <q-space />
                <div>TRATAMIENTOS ONCOLÓGICOS PREVIOS</div>
                <q-space />
            </q-bar>
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Terapia Sistémica</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="paciente.terapias" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueTerapias" :options="itemsfiltroTerapias" @filter="filtroTerapias"
                label="Terapia sistémica" />
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Radioterapia</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="paciente.radioterapias" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueRadioterapias" :options="itemsfiltroRadioterapias" @filter="filtroRadioterapias"
                label="Radioterapia" />
        </div>
        <div class="col-12 q-mt-lg row">
            <div class="doc-h2">Procedimiento Quirúrgico</div>
            <q-space />
            <div class="element-linea"></div>
        </div>
        <div class="col-12 col-md-12">
            <q-select filled v-model="paciente.procedimientos" dense use-input use-chips multiple input-debounce="0"
                @new-value="createValueProcedimientos" :options="itemsfiltroProcedimientos"
                @filter="filtroProcedimientos" label="Procedimiento quirúrgico" />
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar'
export default {
    components: {
    },
    data() {
        return {
            itemsfiltroTerapias: null,
            itemsfiltroRadioterapias: null,
            itemsfiltroProcedimientos: null,
        }
    },
    computed: {
        paciente() {
            return this.$store.state.pacienteStore.antecOncologicos;
        },
        itemsTerapias() {
            return this.$store.state.listaCatalogoTerapias
        },
        itemsRadioterapias() {
            return this.$store.state.listaCatalogoRadioterapias
        },
        itmesProcedimientos() {
            return this.$store.state.listaCatalogoProcedimientos
        },
    },
    watch: {
    },
    created() {
    },
    methods: {
        filtroTerapias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroTerapias = this.itemsTerapias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroTerapias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroRadioterapias(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroRadioterapias = this.itemsRadioterapias.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroRadioterapias.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroProcedimientos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroProcedimientos = this.itmesProcedimientos.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroProcedimientos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        createValueTerapias(val, done) {
            if (val.length > 0) {
                if (!this.itemsTerapias.includes(val)) {
                    this.itemsTerapias.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueRadioterapias(val, done) {
            if (val.length > 0) {
                if (!this.itemsRadioterapias.includes(val)) {
                    this.itemsRadioterapias.push(val)
                }
                done(val, 'toggle')
            }
        },
        createValueProcedimientos(val, done) {
            if (val.length > 0) {
                if (!this.itmesProcedimientos.includes(val)) {
                    this.itmesProcedimientos.push(val)
                }
                done(val, 'toggle')
            }
        },
    }
}
</script>